module.exports = ({
    "CDMS401MSG007": "症例番号がすでに使用されています。",
    "CDMS402MSG013": "施設が削除されたため、被験者が登録できません。",
    "CDMS402MSG007": "クエリーが {total}件発生しました。",
    "CDMS402MSG008" : "入力したデータが破棄されます。\n よろしいでしょうか。",
    "CDMS402MSG011": "保存したページは存在しません。",
    "CDMS402MSG009": "SCRNUMが自動増やすことができません。",

    "CDMS402.MODAL.EDIT" : "修正理由",
    "CDMS402.BUTTON.VERIFI_HANDLE_STAY" : "保存後、新規症例追加",
    "CDMS402.BUTTON.VISIT_FORM_MATRIX" : "Visit&Form Matrix",
    "CDMS402.BUTTON.NEXT_PAGE" : "次のページ",
    "CDMS402.BUTTON.LIST_TO_SUBJECT" : "被験者一覧へ",
    "CDMS402.BOX.CREATE_QUERY": "クエリー作成：",
    "CDMS402.BOX.SELECT_CREATE_QUERY": "クエリーを作成した項目を選択して下さい。",
    "CDMS402.BOX.QUERY_CONTENT": "クエリーの内容を入力して下さい。",
    "CDMS402.BOX.REQUIRED_CONTENT": "クエリーの内容を入力してください。",
    "CDMS402.COMMENT.PLACEHOLDER": "コメント...",
    "CDMS402.COMMENT.BUTTON.DELETE": "削除",
    "CDMS402.COMMENT.BUTTON.EDIT": "修正",
    "CDMS402.COMMENT.LABEL": "【コメント】",

    "CDMS402.AUDITTRAIL.TABLE.DATE": "日付",
    "CDMS402.AUDITTRAIL.TABLE.USER": "ユーザー（使用者）",
    "CDMS402.AUDITTRAIL.TABLE.LABEL": "ラベル",
    "CDMS402.AUDITTRAIL.TABLE.BEFORE": "以前",
    "CDMS402.AUDITTRAIL.TABLE.NEXT": "次",
    "CDMS402.AUDITTRAIL.TABLE.REASON": "理由",
    "CDMS402.AUDITTRAIL.TABLE.REASON.QUERY": "回答",

    "CDMS402.QUERY.TABLE.ITEM": "項目",
    "CDMS402.QUERY.TABLE.GROUP_NUMBER": "グループ番号",
    "CDMS402.QUERY.TABLE.DATE": "日付",
    "CDMS402.QUERY.TABLE.TYPE": "タイプ",
    "CDMS402.QUERY.TABLE.QUERY": "クエリー",
    "CDMS402.QUERY.TABLE.STATUS": "状態",
    "CDMS402.QUERY.EDIT.DELETE": "削除",
    "CDMS402.QUERY.EDIT.CONTENT": "内容編集",
    "CDMS402.QUERY.EDIT.ERROR": "クエリーの内容を入力してください。",

    "CDMS402.COMMENT.TABLE.CONTENT": "コメント",

    "CDMS402.FREEZE.TABLE.QUESTION": "質問",
    "CDMS402.FREEZE.TABLE.DATE": "日付",
    "CDMS402.FREEZE.TABLE.USER": "ユーザー（使用者）",
    "CDMS402.FREEZE.TABLE.STATUS": "状態",
    "CDMS402.QUERY.REOPEN": "再オープン",
});