module.exports = ({
    "CDMS941OPT1": 'Waiting for Use',
    "CDMS941OPT2": 'In Use',

    "CDMS941MSG003": "The selected IP No. has been delivered.",
    "CDMS941MSG001": "Cannot deliver IP No. because it does not exist in the selected source.",
    "CDMS941MSG002": "Cannot deliver IP No. because it is already in use.",

    "CDMS941H001": "Source",
    "CDMS941H002": "Target",

    "CDMS941H003": "No.",
    "CDMS941H004": "Site Name",
    "CDMS941H005": "IP No.",
    "CDMS941H006": "Serial No.",
    "CDMS941H007": "Status",

    "CDMS941BTN001": "Delivery",
})