module.exports = ({
    "CDMS911P001": "Please enter your e-mail address",
    "CDMS911P002": "Please enter your user name",
    "CDMS911P003": "Please enter your phone number",
    "CDMS911P004": "Please enter organization",
    "CDMS911MSG001": "Please enter your e-mail address.",
    "CDMS911MSG002": "This email address has already been registered on this study.",
    "CDMS911MSG003": "This email address has already been registered as the User ID of another user.",
    "CDMS911MSG004": "Please enter your user name.",
    "CDMS911MSG005": "The input name does not match the registered one [{email}]. Please try again.",
    "CDMS911MSG006": "Please enter organization.",
    "CDMS911MSG007": "Please select role.",
    "CDMS911MSG008": "Please select site.",
    "CDMS911MSG009": "Role and site are overlapped.",
    "CDMS912MSG001": "Are you sure you want to delete this role?",
    "CDMS912MSG002": "Please enter Start Date.",

    "CDMS911LB001": "E-mail",
    "CDMS911LB002": "User ID",
    "CDMS911LB003": "User Name",
    "CDMS911LB004": "Phone Number",
    "CDMS911LB005": "Organization",
    "CDMS911LB006": "Disable",
    "CDMS911LB007": "Role",
    "CDMS911LB008": "Site",
    "CDMS911LB009": "Delete",
    "CDMS911LB010": "User",
    "CDMS911LB011": "Start Date",
    "CDMS911LB012": "End Date",
    "CDMS911LB013":"Language",

    "CDMS911TT001": "User Registration",
    "CDMS911TT002": "Study Information",
    "CDMS911TT003": "Site",
    "CDMS911TT004": "User Editor",

    "CDMS911OPT001": "All",
});