module.exports = {
  CDMS970TT001: "eConsent連携履歴",

  CDMS970H001: "No.",
  CDMS970H002: "ユーザーID",
  CDMS970H003: "ユーザー名称",
  CDMS970H004: "開始日",
  CDMS970H005: "終了日",
  CDMS970H006: "施設コード",
  CDMS970H007: "被験者番号",
  CDMS970H008: "登録日",
  CDMS970H009: "状態",
  CDMS970H010: "詳細",

  CDMS970OPT001: "プログレス",
  CDMS970OPT002: "成功",
  CDMS970OPT003: "失敗",

  CDMS970MSG001: "トーケンがありません。",
  CDMS970MSG002: "トークンの有効期限が切れています。",
  CDMS970MSG003:
    "Study{study_id}に対し、eConsentとの連携設定権限がありません。",
  CDMS970MSG004:
    "サイト{site_id}に対し、eConsentとの連携設定権限がありません。",
  CDMS970MSG005: "StudyIDがありません。",
  CDMS970MSG006: "Study{study_id}はCDMSに存在していません。",
  CDMS970MSG007: "Study{study_id}がeConsentと連携されていません。",
  CDMS970MSG008: "Study{study_id}のステータスはDB Lockになっています。",
  CDMS970MSG009: "サイトIDがありません。",
  CDMS970MSG010: "サイト{site_id}はStudy{study_id}に存在していません。",
  CDMS970MSG011: "サイト{site_id}がeConsentと連携されていません。",
  CDMS970MSG012: "サイト{site_id}が無効又は削除されています。",
  CDMS970MSG013: "症例データの同期が完了しました。",
  CDMS970MSG014: "症例IDがありません。",
  CDMS970MSG015: "{subject_id}症例は既に他のサイトIDに存在しています。",
  CDMS970MSG016: "{subject_id}症例に使用できない文字が含まれています。",
  CDMS970MSG017: "{subject_id}症例は256文字以下を入力してください。",
  CDMS970MSG018:
    "サイトには既に{subject_id}という症例IDが存在します。\n症例は置換えずスキップします。",
  CDMS970MSG019:
    "{subject_id}症例の作成に失敗しました。\n未入力の必須項目があります。",
  CDMS970MSG020: "Consent_dateを入力してください。",
  CDMS970MSG021: "consent_dateフォーマットは正しくありません。",
  CDMS970MSG022: "consent_date値は正しくありません。",
  CDMS970MSG023: "サイト{site_id}に対し、症例作成権限がありません。",
  CDMS970MSG024: "Study{study_id}に対し、操作権限がありません。",
  CDMS970MSG025: "登録ページにCONSENT DATEが設定されていません。",
  CDMS970MSG026: "CONSENT DATE項目が削除又は除外されました。",

  CDMS500BTN001: "被験者一覧へ",

  CDMS400MSG003: "登録ページが存在しません。",
  CDMS400MSG004: "複数の登録ページが設定されています。",
  CDMS400MSG005: "登録ページにScreening numberが設定されていません。",
  CDMS400MSG008: "施設のバージョンが正しく設定されないため、症例を登録できません。",
  CDMS400MSG010: "Enrollment numberは256文字以下を設定してください。",
  CDMS401MSG003: "ENRNUMが自動増やすことができません。", 

  COMMSG002: "エラーが発生しました。\n管理者に連絡してください。",
  COMMSG011: "StudyBuilderのデータを反映しています。\nしばらくお待ちください。",
};