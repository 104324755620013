module.exports = ({
    // Placeholder
    "PLACEHORDER" : {
        "CDMS911P001" : "CDMS911P001",
    },
    // Messages
    "MSG" : {
        "CDMS911MSG001" : "CDMS911MSG001。"
    },
    "CDMS700MSG006" : "一部のデータが変更されたため、更新できません。\nページをリフレッシュしてください。",
    // Trans text on pages
    "CDMS703" : {
        "TITLE_PAGE" : "ユーザー管理",

        "FOOTER_BUTTON_SAVE" : "Save",
        "FOOTER_BUTTON_DOWNLOAD" : "Download",

        "TABLE" : {
            "HEADER" : {
                "SITEID" : "SITEID",
                "SUBJECT" : "SUBJECT",
                "VISITID" : "Visit",
                "PAGEID" : "PAGEID",
                "QUESTION_STATUS" : "QUESTION_STATUS",
                "COUNT_OPENED_QUERY" : "COUNT_OPENED_QUERY",
                "COUNT_ANSWERED_QUERY" : "COUNT_ANSWERED_QUERY",
                "STATUS_FREEZE" : "Freeze",
                "STATUS_SDV" : "SDV",
                "STATUS_REVIEW" : "Review",
                "STATUS_LOCK" : "Lock",
                "STATUS_ESIGN" : "E-Sign",
            }
        },
    },

    "CDMS704" : {
        "TITLE_PAGE" : "ステータス(ブロック)",
        "FOOTER_BUTTON_SAVE" : "保存",
        "FOOTER_BUTTON_DOWNLOAD" : "ダウンロード",
        "TABLE" : {
            "HEADER" : {
                "SITEID" : "施設名",
                "SUBJECT" : "被験者番号",
                "VISITID" : "Visit",
                "PAGEID" : "ページ",
                "BLOCKID" : "ブロック",
                "QUESTION_STATUS" : "入力状況",
                "COUNT_OPENED_QUERY" : "オープンクエリー",
                "COUNT_ANSWERED_QUERY" : "クエリー回答",
                "STATUS_FREEZE" : "Freeze",
                "STATUS_SDV" : "SDV",
                "STATUS_REVIEW" : "Review",
                "STATUS_LOCK" : "Lock",
                "STATUS_ESIGN" : "電子署名",
            }
        },
    },
});