module.exports = ({
    "CDMS101P001": "Please enter your e-mail address",
    "CDMS101P002": "Please enter your security answer",
    "CDMS101MSG001":"Please enter your e-mail address.",
    "CDMS101MSG002":"The e-mail has not been registered.",
    "CDMS101MSG003":"Please enter the answer for the security question.",
    "CDMS101MSG004":"The security question you selected is incorrect. Please try again.",
    "CDMS101MSG005":"The password has been initialized.<br>A new password has been sent to the email.",

    "CDMS101BTN001": "Reset",
    "CDMS101LB001": "E-mail",
    "CDMS101LB002": "Select a security question and answer",
    "CDMS101LB003": "Security Question",
    "CDMS101LB004": "Security Answer",
    "CDMS101TT001": "Forgot Password",

    "CDMS102TT001": "Forgot User ID",
    "CDMS102TT002": "Forgot User ID",
    "CDMS102MSG001":"The user id has been sent to the e-mail.",

    "CDMS102BTN001": "Send",
    "CDMS102BTN002": "Return to login",
    "COMBTN025": "Back to Login",
});