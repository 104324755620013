module.exports = ({
    "CDMS941OPT1": '使用待機',
    "CDMS941OPT2": '使用済み',
    "CDMS941MSG003": "選択されたIPNoを搬送しました。",
    "CDMS941MSG001": "選択した搬送元にIPNo.が存在しない為、搬送出来ません。",
    "CDMS941MSG002": "選択したIP No.中には使用済みのIP No.がある為、搬送出来ません。",

    "CDMS941H001": "搬送元",
    "CDMS941H002": "搬送先",
    "CDMS941H003": "No.",
    "CDMS941H004": "施設",
    "CDMS941H005": "IP No.",
    "CDMS941H006": "製造番号",
    "CDMS941H007": "状態",

    "CDMS940H001": "施設",
    "CDMS940H002": "搬送日",
    "CDMS940H003": "IP No.",

    "CDMS940TT001": "搬送状況",
    "CDMS941TT001":		"施設への搬送",
    "CDMS941BTN001": "搬送",
})