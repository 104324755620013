import request from '../APIUtils.js';

export function getListStudy(data) {
    return request({
        url: "/api/study/list",
        method: 'POST',
        body: JSON.stringify(data)
    });
}

/**
 * @author vungpv@ominext.com
 * @param studyId
 * @returns array list studyRoles
 */
export function getStudyRoleMasterDataList(studyId) {
    return request({
        url: "/api/study/role/master-data-list/" + studyId,
        method: 'GET',
        dataType: "json"
    });
}

/**
 *
 * @param studyId
 * @param roleId
 * @param userLoginId
 * @returns {*}
 */
export function getSiteMaster(studyId, roleId, userLoginId) {//TODO: trùng lặp trong SiteAPI
    return request({
        url: "/api/study/"+studyId+"/site/list-master-data",
        method: 'POST',
        dataType: "json",
        body : JSON.stringify({
            studyId : studyId,
            roleId : roleId,
            userLoginId : userLoginId
        })
    });
}

export function getStudyInfo(studyId) {
    return request({
        url: "/api/study/info/" + studyId,
        method: 'GET',
        dataType: "json"
    });
}

export function getStudySetting(studyId) {
    return request({
        url: "/api/study/setting/" + studyId,
        method: 'GET',
        dataType: "json",
    });
}