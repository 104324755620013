module.exports = ({
    "CDMS920LB001": "Protocol No.",
    "CDMS920LB002": "Alias",
    "CDMS920LB003": "Study Name",
    "CDMS920LB004": "Number of Sites",
    "CDMS920LB005": "Number of Subjects",
    "CDMS920LB006": "Contract Number Level Setting",

    "CDMS920TT001": "Study Information",

    "CDMS920OPT001": "No Limitation",
    "CDMS920OPT002": "Study",
    "CDMS920OPT003": "Site",
});