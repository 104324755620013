import request from '../APIUtils.js';

export function getStudyRole(studyId, roleId) {
    return request({
        url: "/api/study/detail?studyId=" + studyId + "&roleId=" + roleId,
        method: 'GET',
        dataType: "json",
    });
}

export function getPrivileges(studyId, roleId) {
    return request({
        url: "/api/study/privilege/" + studyId + "/" + roleId,
        method: 'GET',
        dataType: "json",
    });
}

export function getPrivilegeList(studyId, roleId) {
    return request({
        url: "/api/study/privilege/privilege/" + studyId + "/" + roleId,
        method: 'GET',
        dataType: "json",
    });
}

/**
 *
 * @param studyId
 * @param obj
 * @returns {*}
 */
export function getFilterMasterList(studyId, obj) {
    return request({
        url: "/api/study/" + studyId + "/status/filter-master-list",
        method: 'POST',
        dataType: "json",
        body: JSON.stringify(obj)
    });
}