module.exports = {
    CDMS804TT001: 'Re-Run ECS',
    CDMS804TT002: 'Audit Trail - {subject_id}',

    CDMS804H001: 'No.',
    CDMS804H002: 'Site',
    CDMS804H003: 'Subject ID',
    CDMS804H004: 'ECS Result',
    CDMS804H005: 'ECS Implementer',
    CDMS804H006: 'Start Date',
    CDMS804H007: 'End Date',

    CDMS804LB001: 'Total of subjects',
    CDMS804LB002: 'Total of subjects being failed',
    CDMS804LB003: 'Total of subjects being in progress',

    CDMS804BTN001: 'Run ECS',

    CDMS804OPT001: 'Success',
    CDMS804OPT002: 'In Progress',
    CDMS804OPT003: 'Fail',
    CDMS804OPT004: 'Blank',
    CDMS804OPT005: 'Select all subjects',

    CDMS804MSG001:
        'ECS execution has been completed.<br/>There are {x} subjects being failed. Please try again.',
    CDMS804MSG002: 'Are you sure you want to re-execute ECS?',
    CDMS400MSG007:"ECS cannot be run because there's a disabled subject"
};
