module.exports = ({
    "CDMS800TT001": "User History Report",
    "CDMS800H001": "Organization Information",
    "CDMS800H002": "Country",
    "CDMS800H003": "Organization",
    "CDMS800H004": "User Information",
    "CDMS800H005": "User ID",
    "CDMS800H006": "User Name",
    "CDMS800H007": "Study Information",
    "CDMS800H008": "Role",
    "CDMS800H009": "Site ID",
    "CDMS800H010": "Site Name",
    "CDMS800H011": "Datetime",
    "CDMS800H012": "Status",
    "CDMS800OPT001": "Active",
    "CDMS800OPT002": "Inactive",
});