module.exports = ({

    "CDMS802TT001": "eCRF",
    "CDMS802TT002": "eCRF_History",

    "CDMS802L001": "Study Name",
    "CDMS802L002": "Version",
    "CDMS802L003": "Unique Form",
    "CDMS802L004": "Language",
    "CDMS802OPT001": "Japanese",
    "CDMS802OPT002": "English",
    "CDMS802OPT003": "Annotated CRF",
    "CDMS802OPT004": "Blank CRF",

    "CDMS802BTN001": "Download History",
    "CDMS802BTN002": "eCRF",

    "CDMS802H001": "Study Name",
    "CDMS802H002": "Version",
    "CDMS802H003": "Unique Form",
    "CDMS802H004": "Language",
    "CDMS802H005": "Type",
    "CDMS802H006": "Start Date",
    "CDMS802H007": "End Date",
    "CDMS802H008": "File",
    "CDMS802H009": "Status",

 });