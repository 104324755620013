module.exports = ({
    "CDMS930H001": "割付",
    "CDMS930H002": "因子情報",
    "CDMS930H003": "群情報",
    "CDMS930H004": "更新者",
    "CDMS930H005": "更新日付",
    "CDMS930TT001": "ARM",
    "CDMS931H001": "群情報",
    "CDMS931H002": "IP No.",
    "CDMS931H003": "製造番号",
    "CDMS931H004": "更新者",
    "CDMS931H005": "更新日付",
    "CDMS931TT001": "IP No."
});