module.exports = ({
    "CDMS700P001": "Please enter User ID",
    "CDMS700P002": "Please enter Password",
    "CDMS700MSG001": "Please enter User ID.",
    "CDMS700MSG002": "Please enter Password.",
    "CDMS700MSG003": "The User ID or password you entered is incorrect. Please try again.",
    "CDMS700MSG004": "Data cannot be updated because some data has been changed. Please refresh the page.",
    "CDMS700MSG005": "The status of {level} cannot be set because its item has been locked.",
    "CDMS706H001": "Site",
    "CDMS706H002": "Subject ID",
    "CDMS706H003": "Visit",
    "CDMS706H004": "Page",
    "CDMS706H005": "Block",
    "CDMS706H006": "Line No.",
    "CDMS706H007": "Question",
    "CDMS706H008": "Input Status",
    "CDMS706H009": "Number of Open Queries",
    "CDMS706H010": "Number of Answered Queries",
    "CDMS706H011": "e-Signature",
    "CDMS706H012": "Freeze",
    "CDMS706H013": "SDV",
    "CDMS706H014": "Review",
    "CDMS706H015": "Lock",
    "CDMS700TT001": "Site Status",
    "CDMS701TT001": "Subject Status",
    "CDMS702TT001": "Visit Status",
    // "CDMS703TT001": "Page Status",
    "CDMS704TT001": "Block Status",
    "CDMS705TT001": "Line Status",
    "CDMS706TT001": "Question Status",
    "CDMS706OPT001": "Incompleted",
    "CDMS706OPT002": "Partially Completed",
    "CDMS706OPT003": "Completed",
    "CDMS706OPT004": "N/A",
    "CDMS706LB001": "※By entering the password I guarantee that all data in the selected form is complied with applicable regulations.",
    "CDMS706LB002": "User ID",
    "CDMS706LB003": "Password",
    "CDMS703TT001": "EDC",
    "CDMS703TT002": "ePRO",
});