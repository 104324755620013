module.exports = ({
    "CDMS300TT001": "e-Training",

    "CDMS300BTN001": "証明書をダウンロード",

    "CDMS300H001": "資料名称",
    "CDMS300H002": "修了状況",
    "CDMS300H003": "修了日",

    "CDMS300OPT001": "修了",
    "CDMS300OPT002": "未修了",
    "CDMS300PL001": "ドキュメント",
    "CDMS300PL002": "ページ",

    // system e-training
    "TAB-SYSTEM-ETRAINING": "System e-Training",
    "TABLE-CM-NO": "No.",
    "TABLE-CM-USER-ID": "ユーザーID",
    "TABLE-COLUMN-SITE": "施設",
    "TABLE-COLUMN-ACTIVE-STATUS": "アクティブ",
    "TABLE-COLUMN-ETRAINING-FILE": "教育内容",
    "TABLE-COLUMN-COMPLETION-STATUS": "修了有無",
    "TABLE-COLUMN-COMPLETION-DATE": "修了日",
    "TABLE-COLUMN-DOWNLOAD-CERTIFICATE": "証明書",
    "BUTTON-CM-DOWNLOAD-ALL": "全てダウンロード",
    "LABEL-ACTIVE": "アクティブ",
    "LABEL-INACTIVE": "非アクティブ",
    "LABEL-COMPLETED": "完了",
    "LABEL-INCOMPLETED": "未完了",
    "BUTTON-CM-DOWNLOAD": "ダウンロード",
});
