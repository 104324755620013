module.exports = ({
    "CDMS500MSG001": "You cannot input data for this page because the registration page already exists",
    "CDMS500MSG002": "Are you sure you want to delete the selected visits?",
    "CDMS500MSG003": "Are you sure you want to restore the selected visits?",
    "CDMS500MSG004": "The {item} visit cannot be added because no page is available.",

    "CDMS500BTN001": "Subject List",
    "CDMS500BTN002": "Add Visit",
    "CDMS500BTN003": "Select Unscheduled Visit",

    "CDMS500TT001": "Added Visit Selection",
    "CDMS500TT002": "Unscheduled Visit Selection",
    "CDMS500TT003": "Visit&Form Matrix",

    "CDMS500LB001": "Unscheduled No",
});