module.exports = {
  CDMS970TT001: "eConsent Renkei History",

  CDMS970H001: "No.",
  CDMS970H002: "User ID",
  CDMS970H003: "User Name",
  CDMS970H004: "Start Date",
  CDMS970H005: "End Date",
  CDMS970H006: "SiteID",
  CDMS970H007: "Subject ID",
  CDMS970H008: "Registration Date",
  CDMS970H009: "Status",
  CDMS970H010: "Details",

  CDMS970OPT001: "In progress",
  CDMS970OPT002: "Success",
  CDMS970OPT003: "Fail",
  CDMS970MSG001: "Token is blank.",
  CDMS970MSG002: "Token is expired.",
  CDMS970MSG003:
    "Permission to link eConsent for Study {study_id} is NOT set for this UserID.",
  CDMS970MSG004:
    "Permission to link eConsent for Site {site_id} is NOT set for this UserID.",
  CDMS970MSG005: "StudyID is blank.",
  CDMS970MSG006: "Study {study_id} does NOT exist in CDMS.",
  CDMS970MSG007: "Study {study_id} is NOT set to link eConsent.",
  CDMS970MSG008: "Status of study {study_id} is DB Lock.",
  CDMS970MSG009: "SiteID is blank.",
  CDMS970MSG010: "Site {site_id} does NOT exist in Study {study_id}.",
  CDMS970MSG011: "Site {site_id} is NOT set to link eConsent.",
  CDMS970MSG012: "Status of site {site_id} is deleted or disable.",
  CDMS970MSG013: "Synchronizing Subject data as per request is completed.",
  CDMS970MSG014: "SubjectID is blank.",
  CDMS970MSG015: "Subject {subject_id} exists in other SiteID.",
  CDMS970MSG016: "Subject {subject_id} includes invalid character(s).",
  CDMS970MSG017: "Subject {subject_id} includes more than 256 characters.",
  CDMS970MSG018:
    "Skip Subject {subject_id} because this Subject exists in this Site.",
  CDMS970MSG019:
    "Subject {subject_id} can not be created because open queries have issued.",
  CDMS970MSG020: "Consent_date is blank.",
  CDMS970MSG021: "The consent_date format is incorrect.",
  CDMS970MSG022: "The value of consent_date is invalid.",
  CDMS970MSG023:
    "Permission to create Subject for Site {site_id} is NOT set for this UserID.",
  CDMS970MSG024: "UserID is NOT enable in Study {study_id}.",
  CDMS970MSG025: "CONSENT DATE is not set for the Registration Page.",
  CDMS970MSG026: "Item CONSENT DATE is deleted or EXCLUDE.",

  CDMS500BTN001: "Subject List",

  CDMS400MSG003: "Registration Page is not existing.",
  CDMS400MSG004: "Multiple Registration Pages are existing.",
  CDMS400MSG005: "Screening Number is not set for the Registration Page.",
  CDMS400MSG008: "The subject cannot be registered because the site version is not set properly.",
  CDMS400MSG010: "Enrollment number must be less than or equal to 256 characters.",
  CDMS401MSG003: "ENRNUM cannot be increased automatically.", 

  COMMSG002: "A server error occurred. \nPlease contact the administrator.",
  COMMSG011: "Configuration settings is being released from StudyBuilder. Please wait.",
};
