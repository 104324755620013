module.exports = ({
    "CDMS400.CONFIRM.TITLE": "確認",
    "CDMS400.TITLE": "症例一覧",
    "CDMS400MSG002": "被験者を有効化します。\nよろしいですか。",
    "CDMS400MSG003": "被験者を無効化します。\nよろしいですか。",
    "CDMS400.SITENAME": "施設名",
    "CDMS400.STATUS": "ステータス",
    "CDMS400.REGISTER.DATE": "登録日",
    "CDMS400.SUBJECTNO": "被験者番号",
    "CDMS400.SITECODE": "施設コード",
    "CDMS400.OPENQUERY": "オープンクエリー数",
    "CDMS400.BTN.DOWNLOAD": "ダウンロード",
    "CDMS400.BTN.ENDABLE": "無効化",
    "CDMS400.BTN.DISABLE": "再有効化",
    "CDMS400.BTN.ADD.SUBJECT": "被験者追加",
});