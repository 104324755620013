module.exports = ({

    "CDMS900MSG001": "選択した施設を削除します。\nよろしいですか。",
    "CDMS900MSG002": "選択した施設を回復します。\nよろしいですか。",
    "CDMS900MSG003": "選択中の施設には被験者がすでに登録されているため、削除できません。",
    "CDMS900MSG004": "選択中の施設にはIP No.が設定されているため、削除できません。",

    "CDMS900H001": "施設コード",
    "CDMS900H002": "施設名",
    "CDMS900H003": "国名",
    "CDMS900H004": "契約例数",
    "CDMS900H005": "契約日",
    "CDMS900H006": "Audit Trail",
    "CDMS900H007": "住所",
    "CDMS900H008": "標準時間帯",
    "CDMS900H009": "バージョン",
    "CDMS900H010": "適用日",
    "CDMS900H011": "診療科",
    "CDMS900H012": "修正者",
    "CDMS900H013": "修正日付",
    "CDMS900H014": "eConsent",

    "CDMS900TT001": "施設管理",
    "CDMS900TT002": "{siteCode}修正履歴",

});