module.exports = ({
   "CDMS707MSG004": "オープンクエリーまたは回答クエリーがあるため、DB Lockできません。",
   "CDMS402BTN001": "レポート出力",
   "CDMS402BTN002": "DB Lock",
   "CDMS402BTN003": "DB Unlock",
   "CDMS707TT002": "DB Lock status",
   "CDMS707TT003": "DB Lock 履歴",
   "CDMS707LB001": "試験ID",
   "CDMS707H001": "試験名",
   "CDMS707H002": "Status",
   "CDMS707H003": "事由",
   "CDMS707LB003": "ステータス",
   "CDMS707LB004": "被験者数",
   "CDMS707LB005": "電子署名した症例数",
   "CDMS707LB006": "CRF ページ数",
   "CDMS707LB007": "格納したCRF ページ数",
   "CDMS707LB008": "総オープンクエリー",
   "CDMS707LB009": "総回答クエリー",
   "CDMS707H004": "電子署名",
   "CDMS707H005": "電子署名日付"
});