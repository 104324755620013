module.exports = ({

    "CDMS950TT001": "Blind",
    "CDMS950PTT001": "Audit Trail - {subject_no}",
    "CDMS951P001": "Please input reason",

    "CDMS950MSG001": "Please input reason for emergency key open.",
    "CDMS950MSG002": "Request for key open has been sent.",
    "CDMS950MSG003": "Please enter security code.",
    "CDMS950MSG004": "Request for key open has been approved.",
    "CDMS950MSG005": "Incorrect security code.",
    "CDMS950MSG006": "Request for key open has been rejected.",
    "CDMS950MSG007": "Approver is missing. Unblind request has been canceled.",
    "CDMS950MSG008": "Requestor is missing. Cannot proceed to send email.",

    "CDMS951BTN001": "Request Key Open",
    "CDMS952BTN001": "Cancel Request",
    "CDMS952BTN002": "Re-request",
    "CDMS956BTN001": "Approve",
    "CDMS956BTN002": "Reject",

    "CDMS951L001": "Please input reason for emergency key open.",
    "CDMS952L001": " A request for key open for [{site}][{subjectID}] is waiting for approval.\n Please click the below button for cancelling or re-requesting.",
    "CDMS953L001": "Are you sure you want to cancel the request for key open of [{site}][{subjectID}]?",
    "CDMS955L001": "Please enter the security code to approve a request for key open. \n ※ Please check security code in your email.",
    "CDMS956L001": "Please choose [Approve] or [Reject] after verifying the reason for key open.",
    "CDMS957L001": "Please enter the security code to unblind ARM. \n ※ Please check security code in your email.",

    "CDMS950L001": "Blind",
    "CDMS950L002": "Waiting for Approval",
    "CDMS950L003": "Waiting for Key Open",

    "CDMS950H001": "No.",
    "CDMS950H002": "Site Name",
    "CDMS950H003": "Subject ID",
    "CDMS950H004": "Stratification Group",
    "CDMS950H005": "IP No.",
    "CDMS950H006": "ARM",
    "CDMS950H007": "Reason",
    "CDMS950H008": "Audit Trail",
    
    "CDMS950PH001": "No.",
    "CDMS950PH002": "Site Name",
    "CDMS950PH003": "Stratification Group",
    "CDMS950PH004": "ARM",
    "CDMS950PH005": "Reason",
    "CDMS950PH006": "Updated time",
    "CDMS950PH007": "Updated User",

    "CDMS955P001": "Please enter the security code",

    "CDMS958L001": "A request for key open for [{site}][{subjectID}] is approved. \nPlease click the below button for key opening or re-requesting.",
    "CDMS958BTN002": "Key Open",

    "CDMS950MSG009":	"Security code is expired.",

})