module.exports = ({
    // Placeholder
    "PLACEHORDER" : {

    },
    // Messages
    "MSG" : {
        "CDMS911MSG001" : "CDMS911MSG001  (ja)",
    },
    "CDMS700MSG006" : "一部のデータが変更されたため、更新できません。\nページをリフレッシュしてください。",
    "CDMS700MSG007" : "{level}の中にLockされた対象がありましたため、その対象に関係ないものだけでステータスが設定されました。",
    // Trans text on pages
    "CDMS703" : {
        "TITLE_PAGE" : " ステータス（ページ）",

        "FOOTER_BUTTON_SAVE" : "保存",
        "FOOTER_BUTTON_DOWNLOAD" : "ダウンロード",

        "TABLE" : {
            "HEADER" : {
                "SITEID" : "施設",
                "SUBJECT" : "被験者番号",
                "VISITID" : "Visit",
                "PAGEID" : "ページ",
                "BLOCK_LABEL" : "ブロック",
                "ROW_NO" : "ラインNo.",
                "QUESTION_LABEL" : "質問",
                "QUESTION_STATUS" : "入力状況",
                "COUNT_OPENED_QUERY" : "オープンクエリー",
                "COUNT_ANSWERED_QUERY" : "クエリー回答",
                "STATUS_FREEZE" : "Freeze",
                "STATUS_SDV" : "SDV",
                "STATUS_REVIEW" : "Review",
                "STATUS_LOCK" : "Lock",
                "STATUS_ESIGN" : "電子署名",
            }
        },
    },
    "CDMS705" : {
        "TITLE_PAGE": "ステータス(ログライン)",
        "SUBMIT_SUCCESS": "ステータスが保存されました。"
    },
    "CDMS704" : {
        "TITLE_PAGE" : "ステータス(ブロック)",
        "FOOTER_BUTTON_SAVE" : "保存",
        "FOOTER_BUTTON_DOWNLOAD" : "ダウンロード",
        "TABLE" : {
            "HEADER" : {
                "SITEID" : "施設",
                "SUBJECT" : "被験者番号",
                "VISITID" : "Visit",
                "PAGEID" : "ページ",
                "BLOCKID" : "ブロック",
                "QUESTION_STATUS" : "入力状況",
                "COUNT_OPENED_QUERY" : "オープンクエリー",
                "COUNT_ANSWERED_QUERY" : "クエリー回答",
                "STATUS_FREEZE" : "Freeze",
                "STATUS_SDV" : "SDV",
                "STATUS_REVIEW" : "Review",
                "STATUS_LOCK" : "Lock",
                "STATUS_ESIGN" : "電子署名",
            }
        },
    },
    "CDMS706" : {
        "TITLE_PAGE": "ステータス(質問)",
        "SUBMIT_SUCCESS": "ステータスが保存されました。"
    },
});