module.exports = ({
    "CDMS103P001": "Please enter your current email",
    "CDMS103P002": "Please enter your user name",
    "CDMS103P003": "Please enter your new password",
    "CDMS103P004": "Please confirm your new password",
    "CDMS101P002": "Please enter your security answer",

    "CDMS103MSG001": "Please enter your current e-mail.",
    "CDMS103MSG002": "This e-mail address has already been registered.",
    "CDMS103MSG003": "Please enter your user name.",
    "CDMS103MSG004": "Your new password must not match any of your previous passwords.",
    "CDMS103MSG005": "The password must be at least 8 characters long with alphanumeric characters and numbers.",
    "CDMS103MSG006": "Password does not match.",
    "CDMS103MSG007": "Please enter your new password.",
    "CDMS103MSG008": "Please confirm your new password.",
    "CDMS101MSG003": "Please enter the answer for the security question.",

    "CDMS103BTN001": "Back",

    "CDMS103LB001": "User ID",
    "CDMS103LB002": "Current E-mail",
    "CDMS103LB003": "User Name",
    "CDMS103LB004": "New Password",
    "CDMS103LB005": "Confirm New Password",
    "CDMS103LB006": "Security Question",
    "CDMS103LB007": "Security Answer",

    "CDMS103TT001": "User Information",
    "CDMS103TT002": "Basic Information",
    "CDMS103TT003": "Password Modification",
    "CDMS103TT004": "Security Question Modification",

    "CDMS104TT001": "Password Modification",
    "CDMS104LB001": "User ID",
    "CDMS104LB002": "E-mail",
    "CDMS104LB003": "New Password",
    "CDMS104LB004": "Confirm New Password",
    "CDMS104LB005": "Select a security question and answer",
    "CDMS104LB006": "Security Question",
    "CDMS104LB007": "Security Answer",
});