module.exports = ({
    "CDMS930TT001": "ARM",
    "CDMS930H001": "Stratification Group",
    "CDMS930H002": "Stratification Group Label",
    "CDMS930H003": "ARM",
    "CDMS930H004": "Updated User",
    "CDMS930H005": "Updated Time",
    "CDMS931TT001": "IP No.",
    "CDMS931H001": "ARM",
    "CDMS931H002": "IP No.",
    "CDMS931H003": "Serial No.",
    "CDMS931H004": "Updated User",
    "CDMS931H005": "Updated Time" 
});