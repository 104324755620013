module.exports = ({

    "CDMS950TT001": "Blind",
    "CDMS950PTT001": "{ subject_no }の編集履歴",
    "CDMS951P001": "理由を記載してください",

    "CDMS950MSG001": "開鍵依頼の理由を入力してください。	",
    "CDMS950MSG002": "盲検解除要請が送信されました。",
    "CDMS950MSG003": "セキュリティーコードを入力してください。",
    "CDMS950MSG004": "盲検解除を承諾しました。",
    "CDMS950MSG005": "セキュリティーコードが正しくありません。",
    "CDMS950MSG006": "盲検解除を却下しました。",
    "CDMS950MSG007": "承諾者が設定されていないため、盲検解除リクエストをキャンセルしました。",
    "CDMS950MSG008": "リクエスターが設定されていないため、メール送信が失敗しました。",

    "CDMS951BTN001": "開鍵依頼",
    "CDMS952BTN001": "取消",
    "CDMS952BTN002": "再依頼",
    "CDMS956BTN001": "承認",
    "CDMS956BTN002": "却下",

    "CDMS951L001": "エマージェンシーキーオープンの実施理由を記載してください。",
    "CDMS952L001": "[{site}][{subjectID}]の盲検解除要請中です。\n 取消・再申請が必要な場合は下記のボタンを押下してください。",
    "CDMS953L001": "[{site}][{subjectID}]の盲検解除要請を取り消しますか。",
    "CDMS955L001": "盲検解除を実施するため、セキュリティコードを入力してください。\n ※セキュリティコードについてはメールでご確認ください。",
    "CDMS956L001": "エマージェンシーキーオープンの実施理由を確認の上、「承認」「却下」を選択してください。",
    "CDMS957L001": "盲検解除を許可された割付情報を確認するため、セキュリティコードを入力してください。\n ※セキュリティコードについてメールでご確認ください。",

    "CDMS950L001": "盲検",
    "CDMS950L002": "承諾待ち",
    "CDMS950L003": "開封待ち",

    "CDMS950P001": "すごく体調が悪くなった",

    "CDMS950H001": "No.",
    "CDMS950H002": "施設",
    "CDMS950H003": "被験者番号",
    "CDMS950H004": "因子情報",
    "CDMS950H005": "IP No.",
    "CDMS950H006": "割付情報",
    "CDMS950H007": "理由",
    "CDMS950H008": "Audit Trail",
    "CDMS950PH001": "No.",
    "CDMS950PH002": "施設",
    "CDMS950PH003": "因子情報",
    "CDMS950PH004": "割付情報",
    "CDMS950PH005": "理由",
    "CDMS950PH006": "更新日時",
    "CDMS950PH007": "更新者",

    "CDMS955P001": "セキュリティコードを入力してください",

    "CDMS958L001": "[{site}][{subjectID}]の盲検解除要請が承諾されました。\n開鍵・取消・再申請のいずれかを押下してください。",

    "CDMS958BTN002":	"開鍵",

    "CDMS950MSG009":	"セキュリティコードが無効になりました。",
})
