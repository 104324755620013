module.exports = ({
    "CDMS803TT001": "Subject PDF",
    "CDMS803TT002": "Subject PDF_History",
    "CDMS803H001": "施設",
    "CDMS803H002": "症例番号",
    "CDMS803H003": "Visit",
    "CDMS803H004": "ページ",
    "CDMS803H005": "施設数",
    "CDMS803H006": "被験者数",
    "CDMS803H007": "ユーザーID",
    "CDMS803H008": "ユーザー名称",
    "CDMS803H009": "開始日",
    "CDMS803H010": "終了日",
    "CDMS803H011": "ファイル",
    "CDMS803H012": "状態",
    "CDMS803BTN001": "ダウンロード 履歴",
    "CDMS803BTN002": "ダウンロード",
    "CDMS803BTN003": "Subject PDF画面へ",
    "CDMS803OPT001": "削除被験者",
    "CDMS803OPT002": "成功",
    "CDMS803OPT003": "失敗",
    "CDMS803OPT004": "プログレス",
    "CDMS803OPT005": "N/A"
});