module.exports = ({
    "CDMS801TT001": "Dataset",
    "CDMS801TT002": "Dataset_History",
    "CDMS801LB001": "出力条件",
    "CDMS801LB002": "実施状況",
    "CDMS801H001": "施設",
    "CDMS801H002": "症例番号",
    "CDMS801H003": "Visit ",
    "CDMS801H004": "ソースの選択",
    "CDMS801H005": "ドメインの選択",
    "CDMS801H006": "ユーザーID",
    "CDMS801H007": "ユーザー名称",
    "CDMS801H008": "開始日",
    "CDMS801H009": "終了日",
    "CDMS801H010": "ファイル",
    "CDMS801H011": "状態",
    "CDMS801BTN001": "ダウンロードExcel",
    "CDMS801BTN002": "ダウンロードCSV",
    "CDMS801BTN003": "ダウンロードAccess",
    "CDMS801BTN004": "ダウンロードSAS",
    "CDMS801BTN005": "ダウンロード履歴",
    "CDMS801BTN006": "Dataset画面へ",
    "CDMS801OPT001": "コード",
    "CDMS801OPT002": "ラベル",
    "CDMS801OPT003": " コード＆ラベル",
    "CDMS801OPT004": "SDV",
    "CDMS801OPT005": "Review",
    "CDMS801OPT006": "Freeze",
    "CDMS801OPT007": "Lock",
    "CDMS801OPT008": "eSign",
    "CDMS801OPT009": "入力データ",
    "CDMS801OPT010": "システム情報",
    "CDMS801OPT011": "監査証跡",
});