import { ACCESS_TOKEN, DB_LOCKED_ERROR_CODE, KEY_STUDY_DB_LOCK, CURRENT_USER,SELECTED_LOCALE } from 'constants/index';
import { notification } from 'antd';
import intl from "react-intl-universal";
import { urlActionMap } from './Helpers';

const LANGUAGE_JAPANESE = 1;
const LANGUAGE_ENGLISH = 2;

const request = (options, isAddContentType = true, isResultJson = true, ContentType = "application/json", selectedLanguage = 0) => {
    let selectedLocale = localStorage.getItem(SELECTED_LOCALE);
    let acceptLanguage = (!selectedLocale || selectedLocale === "en-US") ? 'en' : 'jp'
    if(selectedLanguage !== 0){ // neu man hinh co select language => uu tien
        acceptLanguage = selectedLanguage === LANGUAGE_JAPANESE ? 'jp' : 'en';
    } 
    console.log('acceptLanguage', selectedLanguage, acceptLanguage)
    const headers = new Headers({
        'Accept-Language': acceptLanguage
    })

    if (isAddContentType) {
        headers.append('Content-Type', ContentType);
    }
    
    let currentUserLoginId = 0;
    if (localStorage.getItem(CURRENT_USER)) {
        currentUserLoginId = localStorage.getItem(CURRENT_USER);
    }

    if (currentUserLoginId != window.currentUser && window.currentUser > 0 && currentUserLoginId > 0) {
        window.location.href = "/";
        return false;
    }
    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }
    if (typeof options.headersOpt !== 'undefined' && typeof options.headersOpt.dblock !== 'undefined' && options.headersOpt.dblock === true) {
        headers.append('dblock', 'true')
    }
    let studyId =  0;
    try {
        let url = options.url.split("/");
         studyId = parseInt(url[3], 0);
    }
    catch{}

    let currentUrl = window.location.href;
    headers.append("url", currentUrl);
    if(currentUrl.includes("/study/")){
        let studyInUrl = 0 ;
        let roleInUrl = 0;
        let siteInUrl = 0;
        let studyMatch = currentUrl.match(/\/study\/[1-9][0-9]*\//g);
        if (studyMatch){
            studyInUrl = parseInt(studyMatch[0].replaceAll(/[^\d]/g, ""));
            headers.append("studyId", studyInUrl);
        } else {
            headers.append("studyId", 0);
        }
        let roleMatch = currentUrl.match(/\/role\/[1-9][0-9]*\//g);
        if (roleMatch){
            roleInUrl = parseInt(roleMatch[0].replaceAll(/[^\d]/g, ""));
            headers.append("roleId", roleInUrl);
        } else {
            headers.append("roleId", 0);
        }

        let privilege = "privilege_" + studyInUrl + "_" + roleInUrl;
        if(localStorage.getItem(privilege)){
            headers.append("privilege", localStorage.getItem(privilege));
        }
        
        let siteMatch = currentUrl.match(/\/site\/[1-9][0-9]*\//g);
        if (siteMatch){
            siteInUrl = parseInt(siteMatch[0].replaceAll(/[^\d]/g, ""));
            headers.append("siteId", siteInUrl);
        } else {
            headers.append("siteId", 0);
        }

        for(var urlReg in urlActionMap) {
            if(currentUrl.match(urlReg)) {
                headers.append("action", urlActionMap[urlReg]);
                break;
            }
        }
    }

    let keyDBLock = KEY_STUDY_DB_LOCK + studyId ;
    let currentValueDblock = (localStorage.getItem(keyDBLock)) ? localStorage.getItem(keyDBLock) : '';
    currentValueDblock = (typeof currentValueDblock =="undefined") ? 0: currentValueDblock;
    headers.append('currentValueDblock', currentValueDblock);
    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);
    const online = window.navigator.onLine;

    if (!online) {
        notification.error({
            key : 'COMMSG003',
            message: intl.get("COMTT002"),
            description: intl.get("COMMSG003")
        });
        return Promise.reject({});
    }

    return fetch(options.url, options, 10000) // throw after max 10 seconds timeout error
        .then(response => {
            if (!response.ok) {
                // When internet connection error
                if (!online) {
                    notification.error({
                        key : 'COMMSG003',
                        message: intl.get("COMTT002"),
                        description: intl.get("COMMSG003")
                    });
                    return false;
                }

                fetchErrorHandler(response.status,studyId,currentValueDblock);
                return Promise.reject(response);
            }
            if (isResultJson){
                return response.json().then(json => {
                    if (!response.ok) {
                        return Promise.reject(json);
                    }
                    return json;
                })
            }
            return response;
        });
};

function fetchErrorHandler(status,studyId,oldValueDblock ) {
    switch (status) {
        case 401:
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(CURRENT_USER);
            if (window.location.pathname !== '/login') {
                window.location.href = "/login";
            }
            break;
        case DB_LOCKED_ERROR_CODE:
            let dblock = oldValueDblock === "1" ? "0" : "1" ;
            localStorage.setItem(KEY_STUDY_DB_LOCK + studyId , dblock);
            if (dblock !== oldValueDblock) {
                notification.error({
                    key: 'COMMSG013',
                    message: intl.get("COMTT002"),
                    description: intl.getHTML("COMMSG013")
                });
            }
           // window.location.reload();
            break;
        case 504:
            notification.error({
                key: 'COMMSG004',
                message: intl.get("COMTT002"),
                description: intl.get("COMMSG004")
            });
            break;

        case 500:
        case 400:
            notification.error({
                key: 'COMMSG002',
                message: intl.get("COMTT002"),
                description: intl.getHTML("COMMSG002")
            });
            break;
        case 417: 
            notification.error({
                key: 'COMMSG011',
                message: intl.get("COMTT002"),
                description: intl.getHTML("COMMSG011")
            });
            break;
            case 501: 
            notification.error({
                key: 'COMMSG011',
                message: intl.get("COMTT002"),
                description: intl.getHTML("COMMSG015")
            });
            break;
        case 420: 
            notification.error({
                key: 'COMMSG022',
                message: intl.get("COMTT002"),
                description: intl.getHTML("COMMSG022")
            });
            break; 
        case 413:
            // HttpStatus.PAYLOAD_TOO_LARGE
            notification.error({
                key: 'COMMSG008',
                message: intl.get("COMTT002"),
                description: intl.get("COMMSG008")
            });
            break;

        case 403:
            window.location.href = "/";
            break;

        case 404:
            window.location.href = "/404";
            break;

        case 421:
            // study close
            notification.error({
                key: 'COMMSG024',
                message: intl.get('COMTT002'),
                description: intl.getHTML('COMMSG024'),
            });
            setTimeout(() => {
                window.location.href = '/';
            }, 500);
            break;

        default:
            break;
    }
}

export default request;