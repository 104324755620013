module.exports = {
    CDMS804TT001: 'ECS実施',
    CDMS804TT002: '{subject_id}の編集履歴',

    CDMS804H001: 'No.',
    CDMS804H002: '施設',
    CDMS804H003: '被験者番号',
    CDMS804H004: 'ECS実施結果',
    CDMS804H005: 'ECS実施者',
    CDMS804H006: 'ECS実施開始日',
    CDMS804H007: 'ECS実施終了日',

    CDMS804LB001: '選択した科目の合計',
    CDMS804LB002: '失敗した被験者の総数',
    CDMS804LB003: '進行中の被験者の総数',

    CDMS804BTN001: 'ECS実施',

    CDMS804OPT001: '成功',
    CDMS804OPT002: '進行中',
    CDMS804OPT003: '失敗',
    CDMS804OPT004: '空欄',
    CDMS804OPT005: 'すべての科目を選択',
    
    CDMS804MSG001:
        'ECS実行が完了しました。<br/>失敗した被験者は{x}人います。 もう一度やり直してください。',
    CDMS804MSG002: 'ECSを実施します。\n よろしいですか。',

    CDMS400MSG007: '無効化した症例があるため、ECSが実施できません。',
};
