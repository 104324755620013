module.exports = ({
    "CDMS910MSG001": "Are you sure you want to initialize the password?",
    "CDMS910MSG002": "The password has been initialized.\nA new password has been sent to the email.",
    "CDMS910MSG003": "Are you sure you want to disable the selected users?",
    "CDMS910MSG004": "Are you sure you want to enable the selected users?",

    "CDMS910H001": "User ID",
    "CDMS910H002": "User Name",
    "CDMS910H003": "Start Date",
    "CDMS910H004": "End Date",
    "CDMS910H005": "Password Last Updated Time",
    "CDMS910H006": "Organization",
    "CDMS910H007": "Role",
    "CDMS910H008": "Status",
    "CDMS910H009": "Password Initialization",

    "CDMS910H010": "Audit",
    "CDMS910H011": "No.",
    "CDMS910H012": "E-mail",
    "CDMS910H013": "Phone Number",
    "CDMS910H014": "Updated User",
    "CDMS910H015": "Updated Date",
    "CDMS910H016": "Disable",

    "CDMS910H017": "Language",

    "CDMS910TT001": "User Management",
    "CDMS910TT002": "Audit Trail - {userLoginId}",

    "CDMS910OPT001": "Active",
    "CDMS910OPT002": "Inactive",
    "CDMS910OPT003": "Locked",
    "CDMS910OPT004": "Not yet",
});