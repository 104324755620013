module.exports = ({

    "CDMS500MSG001": "登録ページがすでに存在したため、このページを入力できません。",
    "CDMS500MSG002": "規定外Visitを削除します。\nよろしいですか。",
    "CDMS500MSG003": "規定外Visitを回復します。\nよろしいですか。",
    "CDMS500MSG004": "{item}はページが設定されていないため、規定外Visitが追加できません。",

    "CDMS500BTN001": "被験者一覧へ",
    "CDMS500BTN002": "Visit追加",
    "CDMS500BTN003": " 規定外Visit選択",
   
    "CDMS500TT001": "追加Visit選択",
    "CDMS500TT002": "規定外Visit選択",
    "CDMS500TT003": "Visit&Form Matrix",

    "CDMS500LB001": "規定外No",
});