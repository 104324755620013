module.exports = ({
    "PLACEHOLDER": {

    },

    "CDMS800" : {
        "BUTTON" : {
            "DOWNLOAD_EXCEL" : "ダウンロード"
        },
        "TABLE" : {
            "HEADER" : {
                "INFO_ORGANIZATION" : "組織情報",
                "INFO_USER" : "ユーザー情報",
                "INFO_STUDYROLE" : "試験情報",

                "COUNTRYCODE" : "国名",
                "ORGANIZATION" : "組織名称",

                "USERLOGINID" : "ユーザーID",
                "USERNAME" : "ユーザー名称",

                "ROLENAME" : "ロール",
                "SITECODE" : "施設コード",
                "SITENAME" : "施設名",
                "UPDATETIME" : "日付",
                "STATUS" : "ステータス",
            }
        }
    }

    // Message
});