import moment from 'moment';
import _ from 'lodash';
import {getRoles, hasRole} from "./Role";
import { FREEZE_EDIT, SDV_EDIT, REVIEW_EDIT, LOCK_EDIT, SIGNATURE_EDIT, FREEZE_VIEW, LOCK_VIEW, REVIEW_VIEW, SDV_VIEW, SIGNATURE_VIEW, ECS_RE_EXECUTE, SUBJECT_DISABLE, PRO_LINK_ACTIVE } from 'constants/index';
import { CONSTANTS } from './AppConstants';
import * as CryptoJS from 'crypto-js';


export function getLocalUtc(isNumber = true) {
	let offset = new Date().getTimezoneOffset(),
		o = Math.abs(offset);
	if (typeof isNumber !== "undefined" && isNumber === true) {
		return offset < 0 ? (o / 60) : (o / 60)*(-1)
	}

	return (offset < 0 ? "+" : "-") + (Math.floor(o / 60)); //.slice(-2);
}

export function getLocalUtcInMinutes(){
	let offset = new Date().getTimezoneOffset();
	return -1*offset;
}

export function getDateUtc(dateTime, isString = false) {
	let offset = new Date(dateTime).getTimezoneOffset(),
		o = Math.abs(offset);
	if (isString == false) {
		return Math.floor(o / 60);
	}
	
	return (offset < 0 ? "+" : "-") + (Math.floor(o / 60)); //.slice(-2);
}

function f(n) {
	return n < 10 ? '0' + n : n;
}

export function isFutureDate(value){
	if(_.isString(value) && value.trim() === ""){
		return false;
	}
	value = value.toString();
	value = value.replace('年', '-');
	value = value.replace('月', '-');
	value = value.replace('日', '');
	value = value.toUpperCase();
	value = getDateStrPad(value);

	let isFutureDate = true;
	if(moment(value).isSameOrBefore(moment()) || value === "UNUN-UN-UN"){
		isFutureDate = false;
	}else{
		if(value.includes("UN") && !value.includes("UNUN")){
			//ex: 2020-UN-UN - 2020-09-UN - 2020-UN-08
			let checkFormat = "";
			let checkValue = value;
			if(value.includes("-UN-UN")){
				checkFormat = "YYYY";
			}else if(value.includes("-UN-")){
				checkFormat = "YYYY";
				checkValue = value.split("-UN-")[0];
			}else{
				checkFormat = "YYYY-MM";
				checkValue = value.replace("-UN", "");
			}

			isFutureDate = !moment(checkValue, checkFormat).isSameOrBefore(moment().format(checkFormat));
		}else{
			if(value.includes("UNUN")){   
				isFutureDate = false;
			}
		}
	}
	return isFutureDate;
}

export function currentFullDate(){
	var today = new Date();
	var dd = today.getDate();
	var mm = today.getMonth() + 1;

	var yyyy = today.getFullYear();
	if (dd < 10) {
		dd = '0' + dd;
	}
	if (mm < 10) {
		mm = '0' + mm;
	}
	return yyyy + "" + mm + "" + dd;
}

export function currentDateTime(){
	let d = new Date();
	let date = d.getUTCFullYear() + '-' + f(d.getUTCMonth() + 1) + '-' + f(d.getUTCDate());
	let time = f(d.getUTCHours()) + ":" + f(d.getUTCMinutes()) + ":" + f(d.getUTCSeconds());
	return date + ' ' + time;
}


/**
 * format DEFAULT_DATETIME_FORMAT "yyyy-MM-dd HH:mm:ss";
 */
export function toDateTimeString(datetime) {
    let date = datetime.getFullYear() + '-' + f(datetime.getMonth() + 1) + '-' + f(datetime.getDate());
    let time = f(datetime.getHours()) + ":" + f(datetime.getMinutes()) + ":" + f(datetime.getSeconds());
    return date + ' ' + time;
}

export function convertJapanDateTime(dateTimeString, isDay = false){
	if (!dateTimeString){
		return "";
	}
	let d = new Date(dateTimeString);
	let date = d.getFullYear() + '年' + f(d.getMonth() + 1) + '月' + f(d.getDate()) + '日';
    let time = f(d.getHours()) + ":" + f(d.getMinutes()) + ":" + f(d.getSeconds());
    if (isDay) return date;
	return date + ' ' + time + "（UTC+" + -(d.getTimezoneOffset() / 60) + "）" ;
}

export function convertEnglishDateTime(dateTimeString, isDay = false){
	if (!dateTimeString){
		return "";
	}
	let d = new Date(dateTimeString);
	let date = d.getFullYear() + '-' + f(d.getMonth() + 1) + '-' + f(d.getDate());
    let time = f(d.getHours()) + ":" + f(d.getMinutes()) + ":" + f(d.getSeconds());
    if (isDay) return date;
	let timeZone = -(d.getTimezoneOffset() / 60);
	let sign = timeZone >= 0 ? "+" : "";
	return date + ' ' + time + "（UTC" + sign + timeZone + "）" ;
}

export function convertEnglishDateTimeWithOutUtc(dateTimeString, isDay = false){
	if (!dateTimeString){
		return "";
	}
	let d = new Date(dateTimeString);
	let date = d.getFullYear() + '-' + f(d.getMonth() + 1) + '-' + f(d.getDate());
    if (isDay) {
        return date;
    }

    let time = f(d.getHours()) + ":" + f(d.getMinutes()) + ":" + f(d.getSeconds());
	return date + ' ' + time;
}

export function convertStringLocalDateToStringUTCDateTime(stringLocalDate, isEndDate = false) {
    if (!stringLocalDate || stringLocalDate.trim() === '') {
        return '';
    }
    let localDateTime = new Date(stringLocalDate);
    if (isEndDate) {
        localDateTime.setHours(23, 59, 59, 999);
    } else {
        localDateTime.setHours(0, 0, 0, 0);
    }
    let dateTimeUtc = new Date(localDateTime.getTime() + localDateTime.getTimezoneOffset() * 60000);
    return (
        [
            dateTimeUtc.getFullYear(),
            (dateTimeUtc.getMonth() + 1 + '').padStart(2, '0'),
            (dateTimeUtc.getDate() + '').padStart(2, '0'),
        ].join('-') +
        ' ' +
        [
            (dateTimeUtc.getHours() + '').padStart(2, '0'),
            (dateTimeUtc.getMinutes() + '').padStart(2, '0'),
            (dateTimeUtc.getSeconds() + '').padStart(2, '0')
        ].join(':')
    );
}


export function compareTwoDate(firstDate, lastDate){
	const date1 = new Date(firstDate);
	const date2 = new Date(lastDate);
	const diffTime = Math.abs(date2.getTime() - date1.getTime());
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
	
	return diffDays;
}

export function sortObjectData(data, index, type){
	let current = data[index];
	let currentSeq = current.seq;
	console.log(data);
	console.log(type);
	if (type == "up") {
		for (let i = 1; i < data.length; i++) {
			if (typeof data[index-i] === 'undefined' || data[index-i].deleteFlag) {
				continue;
			}

			let top = data[index-i];
			current.seq = top.seq;
			top.seq = currentSeq;
			data[index-i] = current;
			data[index] = top;
			break;
		}
	}

	if (type == "down") {
		
		for (let i = 1; i < data.length; i++) {
			if (typeof data[index+i] === 'undefined' || data[index+i].deleteFlag) {
				continue;
			}

			if (typeof data[index+i].newItem !== 'undefined') {
				continue;
			}

			let bottom = data[index + i];
			current.seq = bottom.seq;
			bottom.seq = currentSeq;
			data[index+i] = current;
			data[index] = bottom;
			break;
		}
	}

	return data;
}

export function testValidation(rule, value, callback) {
	console.log('value', value);
	console.log('rule', rule);

	callback();
}

export function checkDuplicatePropertyValueInObjects(propertyName, inputArrayObject) {
    var seenDuplicate = false,
        checkObject = {};

        inputArrayObject.map(function (item) {
        var itemPropertyName = item[propertyName];
        if (itemPropertyName in checkObject) {
            checkObject[itemPropertyName].duplicate = true;
            item.duplicate = true;
            seenDuplicate = true;
        }
        else {
            checkObject[itemPropertyName] = item;
            delete item.duplicate;
        }
    });

    return seenDuplicate;
}

String.prototype.format = function() {
    var formatted = this;
    for (var arg in arguments) {
        formatted = formatted.replace("{" + arg + "}", arguments[arg]);
    }
    return formatted;
};


export function replaceIdString(value) {
	value = toASCII(value);
	return value.trim().toUpperCase().replace(/[^A-Z0-9_]/gi, "");
}

function toASCII(chars) {
	// console.log(chars);
	return chars.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)});
	// console.log(chars);
    
}

export function toASCII2(chars){
	var ascii = '';

	for (var i = 0, l = chars.length; i < l; i++) {
        var c = chars[i].charCodeAt(0);
        if (c >= 0xFF00 && c <= 0xFFEF) {
           c = 0xFF & (c + 0x20);
        }
        ascii += String.fromCharCode(c);
	}
	
    return ascii;
}

export function formatOnlyInputInteger(value,maxLength) {
	value = value.replace(/[０-９]/g, function(s) {return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)});
	return value.trim().replace(/[^0-9]|^[0]/gi, "").substring(0,maxLength);
}

export function validateRequired(rule, value, callback) {
    if (value.trim() === "") {
        return callback(this.props.intl.get("COMMSG005", { item: this.props.intl.get("PROPERTY.DATA.TITLE") }));
    }
    return callback();
}

export function convertFullWidthToHalfWidth(value,maxLength=0) {
    // convert [\u3000] (Ideographic Space ) to space  [\u0020]
    value =(value || "").replace ('　',' ');
    //convert full-width to half-width: Fullwidth Exclamation Mark ! => Fullwidth Tilde ~    
    var newValue = value.replace(
        /[\uff01-\uff5e]/g,
        function (ch) { return String.fromCharCode(ch.charCodeAt(0) - 0xfee0); }
    );
    if (maxLength>0){
        return newValue.substring(0,maxLength);
    }
    return newValue;
}

export function isPresenceOfJapanese(rule, value, callback, message, isDisabled) {
    if (isDisabled) {
        return callback();
    }
    //https://unicode-table.com/en/#cjk-symbols-and-punctuation
    // 3000-303F : punctuation
    // 3040-309F : hiragana
    // 30A0-30FF : katakana
    // FF00-FFEF : Full-width roman + half-width katakana
    // 4E00-9FAF : Common and uncommon kanji
    var regex = /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]/g;
    if (regex.test(value)) {
        return callback(message);
    }
    else {
        return callback();
    }
}

export function strLimit(string, length) {
	if (string.length > length) {
		return string.substring(0, length) + "...";
	}

	return string;
}

export function UtcTimeList() {

    let UtcTimeList = [
    	{"time":12,"UTC":"UTC+12 -"},
        {"time":11,"UTC":"UTC+11 -"},
        {"time":10,"UTC":"UTC+10 Canberra, Melbourne, Sydney"},
        {"time":9,"UTC":"UTC+9 Seoul, Tokyo"},
        {"time":8,"UTC":"UTC+8 Baijing, Hongkong, Kuala Lumpur, Manila, Singapore, Taibei"},
        {"time":7,"UTC":"UTC+7 Bangkok, Hanoi, Jakarta"},
        {"time":6,"UTC":"UTC+6 -"},
        {"time":5,"UTC":"UTC+5 -"},
        {"time":4,"UTC":"UTC+4 -"},
        {"time":3,"UTC":"UTC+3 Moscow"},
        {"time":2,"UTC":"UTC+2 Bucharest, Kiev, Sophia"},
        {"time":1,"UTC":"UTC+1 Berlin, Madrid, Paris, Praha, Rome, Warsaw"},
        {"time":0,"UTC":"UTC+0 Lisbon, London"},
        {"time":-1,"UTC":"UTC-1 -"},
        {"time":-2,"UTC":"UTC-2 -"},
        {"time":-3,"UTC":"UTC-3 -"},
        {"time":-4,"UTC":"UTC-4 -"},
        {"time":-5,"UTC":"UTC-5 Eastern Standard Time"},
        {"time":-6,"UTC":"UTC-6 Central Standard Time"},
        {"time":-7,"UTC":"UTC-7 Mountain Standard Time"},
        {"time":-8,"UTC":"UTC-8 Western Standard Time"},
		{"time":-9,"UTC":"UTC-9 -"},
        {"time":-10,"UTC":"UTC-10 -"},
        {"time":-11,"UTC":"UTC-11 -"},
	];
    return UtcTimeList;
}


export function enterInputMobile(value) {
	return value.trim().replace(/[^0-9\-]/gi, "")
}

export function isEmptyObj(obj) {
	for(var key in obj) {
		if(obj.hasOwnProperty(key))
			return false;
	}
	return true;
}

export function IsValidJSONString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function convertToLowerCase(string) {
    if (string && string.length)
        return string.toLowerCase()
    return string
}

/**
 * @author vungpv@ominext.com
 * @param listObjectDefault => [object, object, object]
 * @param listObjectChange  => [object, object, object]
 * @param key string
 * @object : {id : 1, attr_key : "attrName", eSignFlag : true}
 * @return object
 * {
 *     dataChanged : [objChanged, objChanged2],
 *     changedFlag : boolean
 *     eSignChanged : [objChanged, objChanged2],
 *     eSignChangedFlag : boolean
 * }
 * @example
 * compareTwoListObject(
 * 		[{ id: 1, value: "A", eSignFlag : 1 }, { id: 2, value: "B", eSignFlag : 0 }, { id: 3, value: "C", eSignFlag : 1 }],
 * 		[{ id: 1, value: "A1", eSignFlag : 1 }, { id: 2, value: "B1", eSignFlag : 0}, { id: 3, value: "C", eSignFlag : 1 }],
 * 		"eSignFlag"
 * )
 */
export function compareTwoListObject(listObjectDefault, listObjectChange, key = "esignFlag")
{
	let objResult = {}
	const listObjectChanged = _.differenceWith(listObjectChange, listObjectDefault, _.isEqual);

	objResult.dataChanged = listObjectChanged
	objResult.changedFlag = objResult.dataChanged.length ? true : false

	objResult.eSignChanged = _.differenceWith(listObjectChange, listObjectDefault, function (item, other) {
		return item.id === other.id && item[key] === other[key]
	})
	objResult.eSignChangedFlag = objResult.eSignChanged.length ?
		(_.filter(objResult.eSignChanged, { [key] : 1}).length ? true : false) : false

	console.log("objectResult : ", listObjectChange, objResult)
	return objResult
}

export function isValidDate(str) {
    var d = moment(str,'D/M/YYYY');
    if(d == null || !d.isValid()) return false;
  
    return str.indexOf(d.format('D/M/YYYY')) >= 0 
        || str.indexOf(d.format('DD/MM/YYYY')) >= 0
        || str.indexOf(d.format('D/M/YY')) >= 0 
        || str.indexOf(d.format('DD/MM/YY')) >= 0;
  }

export function isChecked(value) {
	return value === 1 ? true : false
}
export function isDisableStatusCheckbox(questionNumber) {
    return questionNumber ? false : true
}

/**
 * @function isDisableStatusCheckbox
 * @param questionNumber
 * @param roles
 * @param roleName
 * @return {boolean}
 * @description Neu khong co quyen hoac so luong question  khong ton tai => true ( disable checkbox )
 * Nguoc lai se false => enable
 */
export function isDisabledCheckboxByRoleAndQuestionStatus(roles, roleName, questionNumber, dbLockFlag = 0, naFlag = 0) {
	if (dbLockFlag === 1 || questionNumber === 0 || !hasRole(roles, roleName) || naFlag === 1) {
		return true;
	}	

	return false;
}

export function disabledCheckBoxStatus(type, roles, roleName, questionNumber, dbLockFlag = 0, openQuery = 0) {	
	if (
		((type == "esignFlag" || type == "lockFlag") && openQuery > 0) ||
		dbLockFlag == 1 ||
		!hasRole(roles, roleName) ||
		!questionNumber
	) {
		return true;
	}

	return false
}

//check điều kiện để disable checkbox, dùng cho màn hình visit, site, subject status
export function isDisableCheckBoxByRoleAndDbLockFlag(roles, roleName, dbLockFlag, totalChilds, naFlag = 0, inputFlag){
	if(hasRole(roles, roleName) && dbLockFlag === 0 && totalChilds && naFlag === 0 && inputFlag !== 0) return false;
	return true;
}
export function isDisableStatusCheckboxLockAndEsign(openQuery, answeredQuery){
	if(openQuery > 0 || answeredQuery > 0) return true;
	return false;
}
export function isUpdateButtonVisible(roles){
	return (hasRole(roles, FREEZE_EDIT) || hasRole(roles, SDV_EDIT) || hasRole(roles, REVIEW_EDIT) || hasRole(roles, LOCK_EDIT) || hasRole(roles, SIGNATURE_EDIT));
}

export function isUpdateButtonVisibleForEProTab(roles){
	return (hasRole(roles, FREEZE_EDIT) || hasRole(roles, REVIEW_EDIT) || hasRole(roles, LOCK_EDIT) || hasRole(roles, SIGNATURE_EDIT));
}

export function isDownloadExcelButtonVisible(roles) {
	return (hasRole(roles, FREEZE_EDIT) || hasRole(roles, SDV_EDIT) || hasRole(roles, REVIEW_EDIT) || hasRole(roles, LOCK_EDIT) || hasRole(roles, SIGNATURE_EDIT) ||
		hasRole(roles, FREEZE_VIEW) || hasRole(roles, SDV_VIEW) || hasRole(roles, REVIEW_VIEW) || hasRole(roles, LOCK_VIEW) || hasRole(roles, SIGNATURE_VIEW) ||
		hasRole(roles, ECS_RE_EXECUTE));
}

export function menuStatusModuleVisible(studyId, roleId) {
    const roles = getRoles(studyId, roleId)
    if (roles === null) {
        window.location.href = "/";
        return false;
    }
	return hasRole(roles, FREEZE_EDIT) || hasRole(roles, FREEZE_VIEW)
			|| hasRole(roles, SDV_EDIT) || hasRole(roles, SDV_VIEW)
			|| hasRole(roles, REVIEW_EDIT) || hasRole(roles, REVIEW_VIEW)
			|| hasRole(roles, LOCK_EDIT) || hasRole(roles, LOCK_VIEW)
			|| hasRole(roles, SIGNATURE_EDIT) || hasRole(roles, SIGNATURE_EDIT)
}

export function isEDCTabVisible(roles) {
	return hasRole(roles, FREEZE_EDIT) || hasRole(roles, FREEZE_VIEW)
			|| hasRole(roles, SDV_EDIT) || hasRole(roles, SDV_VIEW)
			|| hasRole(roles, REVIEW_EDIT) || hasRole(roles, REVIEW_VIEW)
			|| hasRole(roles, LOCK_EDIT) || hasRole(roles, LOCK_VIEW)
			|| hasRole(roles, SIGNATURE_EDIT) || hasRole(roles, SIGNATURE_EDIT)
}

export function statusString(statusFlag, blindFlag = 0, intl) {
	if (statusFlag === 3) {
		return intl.get('CDMS706OPT004');
	}
	if (statusFlag === 0) {
		return intl.get('CDMS706OPT001');
	} else if (statusFlag === 1) {
		return intl.get('CDMS706OPT002');
	} else if (statusFlag === 2){
		return intl.get('CDMS706OPT003');
	} else {
		return null
	}
}

export function statusStringWithQuestionStatusScreen(inputFlag = 0, naFlag = 0, intl) {
	if (naFlag === 1) {
		return intl.get('CDMS706OPT004')
	}

	switch (inputFlag) {
		case 0 :
			return intl.get('CDMS706OPT001')
		case 2 :
			return intl.get('CDMS706OPT003')
		default :
		return intl.get('CDMS706OPT001')
	}
}

/**
 * 未完了：Question chưa được save
 ⮚ 完了：Question đã được save
 * @param value
 */
export function statusInputQuestionString(value, intl) {
	return typeof value !== "undefined" && value > 0 ? intl.get('CDMS706OPT003') : intl.get('CDMS706OPT001') 
}

export function statusInputString(statusFlag) {
	if (statusFlag === 3) {
		return "N/A";
	}
	if (statusFlag === 0) {
		return "未完了"
	} else if (statusFlag === 1) {
		return "一部完了"
	} else if (statusFlag === 2){
		return "完了"
	} else {
		return null
	}
}

export function getLinkUrl402(studyId, roleId, siteId, subjectNo, matrixId) {
	subjectNo = encodeURIComponent(subjectNo); 
	return "/study/" +studyId+ "/role/" + roleId + "/site/" + siteId + "/subject-no/" + subjectNo + "/matrix/" + matrixId
}
export function getLinkUrl500(studyId, roleId, siteId, subjectNo) {
	subjectNo = encodeURIComponent(subjectNo); 
	return "/study/"+ studyId+ "/role/" + roleId + "/site/" + siteId + "/subject-no/" + subjectNo + "/visit-matrix";
}
export function downloadStatusList(studyIdText, screenName, blob) {
	console.log("Running downloadStatusList")
	let dateDownload = currentFullDate();
	let fileName = studyIdText + "_" + screenName + "_"  + dateDownload + ".xlsx";
	let url = window.URL.createObjectURL(new Blob([blob]));
	let link = document.createElement('a');
	
	link.href = url;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	link.parentNode.removeChild(link);
}

export function downloadExportExcelFileWithStudyId(studyIdText, screenName, blob) {
	console.log("Running downloadStatusList")
	let dateDownload = currentFullDate();
	let fileName = studyIdText + "_" + screenName + "_"  + dateDownload + ".xlsx";
	let url = window.URL.createObjectURL(new Blob([blob]));
	let link = document.createElement('a');
	
	link.href = url;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	link.parentNode.removeChild(link);
}

export function downloadExportExcelFile(screenName, blob) {
	let dateDownload = currentFullDate();
	let fileName = screenName + "_"  + dateDownload + ".xlsx";
	let url = window.URL.createObjectURL(new Blob([blob]));
	let link = document.createElement('a');

	link.href = url;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	link.parentNode.removeChild(link);
}

export function downloadECrf(fileName, blob) {
	let dateDownload = currentFullDate();
	let url = window.URL.createObjectURL(new Blob([blob]));
	let link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	link.parentNode.removeChild(link);
}

export function downloadCertificate(fileName,  fileContent) {
	const linkSource = `data:application/pdf;base64,${fileContent}`;
	const downloadLink = document.createElement("a");
	
	downloadLink.href = linkSource;
	downloadLink.download = `${fileName}`;
	document.body.appendChild(downloadLink);
	downloadLink.click();
	downloadLink.parentNode.removeChild(downloadLink);
}

export function downloadCertificatesInStudy(fileName, fileContent) {
	const linkSource = `data:application/zip;base64,${fileContent}`;
	const downloadLink = document.createElement("a");
	
	downloadLink.href = linkSource;
	downloadLink.download = `${fileName}_${getLocalDateTime()}_TRAINING`;
	document.body.appendChild(downloadLink);
	downloadLink.click();
	downloadLink.parentNode.removeChild(downloadLink);
}

export function renameLabelVisit(subjectVisit) {
	let label = subjectVisit.visitLabel;
	if (!label || label.search(/#/) == -1) {
		return label;
	}

	let irregularVisitNo = subjectVisit.irregularVisitNo;
	if (irregularVisitNo == 0) {
		if (subjectVisit.repeatFlag == 0 && subjectVisit.displayFlag == 0) {
			return label;
		}
		return label.replace(/#/g, 1);
	}

	return label.replace(/#/g, irregularVisitNo + 1);
}

/**
 * @author vungpv@ominext.com
 * @param listObject
 * @param listId
 * listObject = [
 * 		{id :  1, deleteFlag : 1},
 * 		{id :  2, deleteFlag : 1},
 * 		{id :  3, deleteFlag : 0},
 * 		{id :  4, deleteFlag : 0}
 * ]
 * listId = [1,2,3,4]
 */
// const listObjectTest = [{id :  1, deleteFlag : 1}, {id :  2, deleteFlag : 1}, {id :  3, deleteFlag : 0}, {id :  4, deleteFlag : 0} ]
// const listIdTest = [1,2,3,4];

export function parseListIdActiveAndDelete(listObject = [], listId = []) {
	let data = {
		deletedArray : [],
		activeArray : [],
	}

	if (typeof listObject === "undefined" || typeof listId === "undefined" || !listId || !listObject) {
		return data
	}

	listId.forEach((id, index) => {
		const obj = _.find(listObject, function(item) { return item.id === id });
		if (obj.deleteFlag=== 0) { //
			data.activeArray.push(id)
		} else if (obj.deleteFlag === 1) {
			data.deletedArray.push(id)
		}
	})

	return data
}

/**
 *
 * @param action DELETE RESTORE
 */
export function disableButtonDeleteRestore(action = "DELETE", listObject = [], listId = []) {
	const data = parseListIdActiveAndDelete(listObject, listId)
	if (action === "DELETE") {
		return data.activeArray.length > 0 ? false : true
	} else if (action === "RESTORE") {
		return data.deletedArray.length > 0 ? false : true
	}

	return true
}

/**
 *
 * @param newObj
 * @param oldObj
 * @returns {*}
 * @unitTest
 * @param newObj
 *  let obj1 = {
 *		a: 1,
 *		b: 2,
 *		c: { foo: 1, bar: 2},
 *		d: { baz: 1, bat: 2 }
 *	}
 *
 * let obj2 = {
 *		b: 2,
 *		c: { foo: 1, bar: 'monkey'},
 *		d: { baz: 1, bat: 2 },
 *		e: 1
 *	}
 */
export function compare2Object(newObj, oldObj) {
	return _.reduce(newObj, function (result, value, key) {
		if (!_.isEqual(value, oldObj[key])) {
			if (_.isArray(value)) {
				result[key] = []
				_.forEach(value, function (innerObjFrom1, index) {
					if (_.isNil(oldObj[key][index])) {
						result[key].push(innerObjFrom1)
					} else {
						let changes = compare2Object(innerObjFrom1, oldObj[key][index])
						if (!_.isEmpty(changes)) {
							result[key].push(changes)
						}
					}
				})
			} else if (_.isObject(value)) {
				result[key] = compare2Object(value, oldObj[key])
			} else {
				result[key] = value
			}
		}
		return result
	}, {})
}

export function renderDateTime(stringDatetime = null) {
	
	if (stringDatetime) {
		let utcTime = new Date(stringDatetime)
		return toDateTimeString(utcTime);
	}
	return null
}

export function convertDateTimeToTimeZone(stringDatetime = null, timezone) {
	
	if (stringDatetime) {
		let utc = getDateUtc(stringDatetime);
		let addHour = timezone - utc;
		let utcTime = new Date(stringDatetime)
		utcTime.setHours( utcTime.getHours() + addHour );
		return toDateTimeString(utcTime);
	}
	return null
}

export function convertDateTimeToTimeZoneAndUTC(stringDatetime = null) {
	let utc = getLocalUtc();
	if (stringDatetime) {
        let utcTime = new Date(stringDatetime)
        let utcString = " (UTC" + (utc >= 0 ? "+" : "-") + utc.toString() + ")";

        utcTime.setHours(utcTime.getHours() + utc );
		return toDateTimeString(utcTime) + utcString;
	}
	return null
}

export function convertDateTimeToTimeZone2(stringDatetime = null, timezone) {
	
	if (stringDatetime) {

		let utcTime = new Date(stringDatetime)
		utcTime.setHours(utcTime.getHours() +  timezone );
		return toDateTimeString(utcTime);
	}
	return null
}

export function getLocalDateTime() {
	var today = new Date();
	return today.getFullYear().toString() + getFullTimeInString(today.getMonth() + 1) + getFullTimeInString(today.getDate()) + getFullTimeInString(today.getHours()) + getFullTimeInString(today.getMinutes()) + getFullTimeInString(today.getSeconds());
}

export function getFormatDateFromString(date, layout) {
	let dateFormatUnAll = "UNUN-UN-UN";
	let dateFormatUMD = "UNUN-MM-DD";
	let dateFormatUUD = "UNUN-UN-DD";
	let dateFormatUMU = "UNUN-MM-UN";
	let dateFormatUnMonth = "YYYY-UN-UN";
	let dateFormatUnDay = "YYYY-MM-UN";
	let dateFormatUnMonthDay = "YYYY-UN-DD";

	if (!date || date.length != 10) {
		return "";
	}

	if (layout == CONSTANTS.DB_ITEM_LAYOUT_DATE_UNUN_UN_UN) {
		if (date.match(/^UNUN-UN-UN/i)) {
			return dateFormatUnAll;
		}
		if (date.match(/^UNUN-[0-9]{2}-[0-9]{2}/i)) {
			return dateFormatUMD;
		}
		if (date.match(/^UNUN-UN-[0-9]{2}/i)) {
			return dateFormatUUD;
		}
		if (date.match(/^UNUN-[0-9]{2}-UN/i)) {
			return dateFormatUMU;
		}
		if (date.match(/^UNUN-UN-UN/i)) {
			return dateFormatUnAll;
		}
	}
	
	if (date.match(/^[0-9]{4}-UN-UN/i) !== null && (layout == CONSTANTS.DB_ITEM_LAYOUT_DATE_YYYY_UN_UN || layout == CONSTANTS.DB_ITEM_LAYOUT_DATE_UNUN_UN_UN)) {
		return dateFormatUnMonth;
	}
	if (date.match(/^[0-9]{4}-[0-9]{2}-UN/i) !== null && (layout == CONSTANTS.DB_ITEM_LAYOUT_DATE_YYYY_UN_UN || layout == CONSTANTS.DB_ITEM_LAYOUT_DATE_UNUN_UN_UN || layout == CONSTANTS.DB_ITEM_LAYOUT_DATE_YYYY_MM_UN)) {
		return dateFormatUnDay;
	}
	if (date.match(/^[0-9]{4}-UN-[0-9]{2}/i) !== null && (layout == CONSTANTS.DB_ITEM_LAYOUT_DATE_YYYY_UN_UN || layout == CONSTANTS.DB_ITEM_LAYOUT_DATE_UNUN_UN_UN)) {
		return dateFormatUnMonthDay;
	}
	return "";
}

export function checkFormatDateAccept(date) {
	let dateFormatAccept = ["YYYY-MM-DD", "YYYY年MM月DD日", "YYYY年M月D日"];
	let isValidDate = 0;
	for (let i = 0; i < dateFormatAccept.length; i++) {
	    if (moment(date, dateFormatAccept[i], true).isValid()) {
	        isValidDate = 1;
	    }
	}
	return isValidDate;
}

export function isValidateItemDate(layout, stringVal, getVal=false) {

	if(_.isString(stringVal) && stringVal.trim() === ""){
		if(getVal){
			return stringVal;
		}else{
			return true;
		}
	}

	if(_.isDate(stringVal)){
		return false;
	}

	let DATE_FORMAT = ['YYYY-MM-DD'];
	switch (layout) {
		case 3:
			DATE_FORMAT.push('YYYY-MM-UN');
			break;
		case 4:
			DATE_FORMAT.push('YYYY-MM-UN', 'YYYY-UN-UN', 'YYYY-UN-DD');
			break;
		case 5:
			DATE_FORMAT.push('YYYY-MM-UN', 'YYYY-UN-UN', 'YYYY-UN-DD', 'UNUN-UN-UN', 'UNUN-UN-DD', 'UNUN-MM-UN', 'UNUN-MM-DD');
			break;
		default:
			break;
	}

	let value = stringVal.toString();
	value = value.replace('年', '-');
	value = value.replace('月', '-');
	value = value.replace('日', '');
	value = value.toUpperCase();
	value = getDateStrPad(value);

	const checkDateByFormats = DATE_FORMAT.map(format => moment(value, format, true).isValid() || value === format);
	let isDateValid = Boolean(checkDateByFormats.find(Boolean));
	const yearFromValue = parseInt(value.slice(0,4));
	
	if (yearFromValue < 1900 && isDateValid == true) {
		isDateValid = false;
	}

	if(getVal){
		if(isDateValid){
			return value;
		}
		return "";
	}
	return isDateValid;
}

export function getFormatFromValue(datetime, layout) {
	if (!datetime) {
		return "YYYY-MM-DD";
	}
	let formatDateUN = getFormatDateFromString(datetime, layout);
	if (formatDateUN) {
		return formatDateUN;
	}
	let formatDateInvalid = checkFormatDateAccept(datetime);
	if (formatDateInvalid) {
		return "YYYY-MM-DD";
	}
}

export function checkDateTimeInVail(datetime, layout) {
	if (!datetime) {
		return 1;
	}
	let formatDateUN = getFormatDateFromString(datetime, layout);
	if (formatDateUN) {
		return 0;
	}
	let formatDateInvalid = checkFormatDateAccept(datetime);
	if (formatDateInvalid) {
		return 0;
	}
	return 1;
}

export function getMommentFormat (initialValue, dateFormat) {
	if (!initialValue) {
		return null;
	}
	if (dateFormat == "UNUN-UN-UN") {
		return moment();
	}
	return moment(initialValue, dateFormat);
}

export function getHoursMinFromString(type, time) {
	var res = time.split(":");
	if (type === "hours") {
		return res[0];
	}
	return res[1];
}

export function getDateStrPad(date) {
	let arrayDate = date.split("-");
	if (arrayDate.length !== 3) {
		return date;
	}
	let year = arrayDate[0];
	let month = arrayDate[1].match(/^UN/i) ? arrayDate[1] : String("00" + arrayDate[1]).slice(-2);
	let day = arrayDate[2].match(/^UN/i) ? arrayDate[2] : String("00" + arrayDate[2]).slice(-2);

	return year + "-" + month + "-" + day;
}

export function checkCorrectTime(time, layout) {

	if(_.isString(time) && time.trim() === ""){
		return true;
	}

	if (!time || time.length != 5) {
		return false;
	}
	let res = time.split(":");
	if (res.length !== 2) {
		return false;
	}

	let hours = getTimeByString(res[0], "hours");
	let min = getTimeByString(res[1], "min");
	if (!hours || !min) {
		return false;
	}
	if (layout === CONSTANTS.DB_ITEM_LAYOUT_TIME_HH_MM && (hours === "UN" || min === "UN")) {
		return false;
	}
	if (layout === CONSTANTS.DB_ITEM_LAYOUT_TIME_HH_UN && hours === "UN") {
		return false;
	}
	return true;
}

function getTimeByString(string, type) {
	if (!string) {
		return "";
	}
	if (string.match(/^UN/i)) {
		return "UN";
	}
	let j = 23;
	if (type === "min") {
		j = 59;
	}
	for (let i = 0; i <= j; i++) {
		let h = String("00" + i).slice(-2);
		if (h === string) {
			return h;
		}
	}
	return "";
}

function getFullTimeInString(time) {
	if (time < 10) {
		return '0' + time;
	}
	return time.toString();
}

export function escapeSearchStr(string = "") {
    if (string === undefined) {
        return "";
    }
    let newString = escapeStr(string.trim());
    return newString;
}

export function escapeStr(string) {
    return ('' + string).replace(/["'\\\n\r\u2028\u2029\t%_]/g, function (character) {
      // Escape all characters not included in SingleStringCharacters and
      // DoubleStringCharacters on
      // http://www.ecma-international.org/ecma-262/5.1/#sec-7.8.4
      switch (character) {
        case '"':
        case "'":
        case '\\':
            return '\\' + character
        // Four possible LineTerminator characters need to be escaped:
        case '\n':
            return '\\n'
        case '\r':
            return '\\r'
        case '\t':
            return '\\t'
        case '\u2028':
            return '\\u2028'
        case '\u2029':
            return '\\u2029'
        case '%':
            return "\\%"
        case '_':
            return "\\_"
        default:
            return character
      }
    })
}

export function disableSubjectCheckBoxByRole(roles) {
	return !hasRole(roles, SUBJECT_DISABLE);
}

export function convertScreenNumRegex(string, isScrnum) {
	if (isScrnum == false) {
		return string;
	}
	return  string.replace(/[^a-zA-Z0-9\{\}\-\_\.\#]/g,'');
}

export function valueFromEventHandle(val, maxLength) {
	let maxLengthString = maxLength.toString();
	val = (val || "").trim();
	// convert số full-width thành số half-width
	val = val.replace(/[０-９]/g, function (s) { return String.fromCharCode(s.charCodeAt(0) - 0xFEE0) });
	//ー－ｰ- 0x30fc, 0xff0d, 0xff70, 0x2d
	val = val.replace(/[\u30FC|\uFF0D|\uff70]/g, function (s) { return "-" });
	//｡．。. 0xff61, 0xff0e, 0x3002, 0x2e
	val = val.replace(/[\uff61|\uff0e|\u3002]/g, function (s) { return "." });
	
	let reg = /^-?\d*\.?(\d*)?$/;
	if (!maxLengthString.includes(".")) {
		reg = RegExp(`^-?\d{0,${parseInt(maxLengthString)}}?$`);
	} else {
		reg = RegExp(`^-?\d{1,${parseInt(maxLengthString.split(".")[0])}}\.?(\d{0,${parseInt(maxLengthString.split(".")[1])}})?$`);
	}	
	if ((!isNaN(val) && reg.test(val)) || val === '' || val === '-') {
		return val;
	}

	let valueTemp = val;
	let negativeNumber = val.startsWith('-');
	// thay thế các ký tự ko phải 
	valueTemp = valueTemp.replace(/[^0-9.]/g, "");
	let arrayMaxLength = maxLengthString.split(".");
	// kiểm tra số được nhập là số nguyên hay số thập phân
	if (arrayMaxLength.length < 1) {
		if (negativeNumber) {
			valueTemp = `-${valueTemp}`;
		}
		return valueTemp;
	}

	let naturalPartMaxLength = parseInt(arrayMaxLength[0]);
	// số nguyên: x,x., x.0
	if (arrayMaxLength.length < 2 || !valueTemp.includes(".")) {
		valueTemp = valueTemp.includes(".") ? valueTemp.replace(/\./g, "") : valueTemp;
		if (valueTemp !== "0" && valueTemp.length > 0) {
			// loại số 0 ở đầu
			let index = 0
			let indexRemove = 0
			for (index = 0; index < valueTemp.length; index++) {
				if (valueTemp.charAt(index) === '0') {
					indexRemove = index + 1;
				} else {
					break;
				}
			}
			valueTemp = valueTemp.slice(indexRemove);
			if (valueTemp === "") {
				valueTemp = "0";
			}
		}
		// ngắt
		if (valueTemp.length > naturalPartMaxLength) {
			valueTemp = valueTemp.substring(0, naturalPartMaxLength);
		}	
	} else {
		// số thập phân
		let decimalPartMaxLength = parseInt(arrayMaxLength[1]);
		//phần nguyên 
		let naturalPart = valueTemp.split(".")[0];
		// loại số 0 đầu
		if (naturalPart !== "0" && naturalPart.length > 0) {
			// loại số 0 ở đầu
			let index = 0
			let indexRemove = 0
			for (index = 0; index < naturalPart.length; index++) {
				if (naturalPart.charAt(index) === '0') {
					indexRemove = index + 1;
				} else {
					break;
				}
			}
			naturalPart = naturalPart.slice(indexRemove);
			if (naturalPart === "") {
				naturalPart = "0";
			}
		}
		// ngắt
		if (naturalPart.length > naturalPartMaxLength) {
			naturalPart = naturalPart.substring(0, naturalPartMaxLength);
		}
		// nhập "."/"-." => convert thành "0."/"-0."
		if (naturalPart.length === 0 && valueTemp.includes(".")) {
			naturalPart = "0";
		}
		// phần thập phân
		let decimalPart = valueTemp.slice(valueTemp.split(".")[0].length);
		decimalPart = decimalPart.includes(".") ? decimalPart.replace(/\./g, "") : decimalPart;
		// ngắt
		if (decimalPart.length > decimalPartMaxLength) {
			decimalPart = decimalPart.substring(0, decimalPartMaxLength);
		}
		valueTemp = `${naturalPart}.${decimalPart}`;
	}
	if (negativeNumber) {
		valueTemp = `-${valueTemp}`;
	}
	return valueTemp;
}

export function isStringEmpty(value) {
	let newValue = (value || "").trim();
	if (newValue === "") {
		return true;
	}
	return false;
}

export function isNumeric(value) {
	let regexNum = /^[+-]?\d*\.?(\d*)?$/;
	return regexNum.test(value);
}

export function getStringAbsoluteValue(value) {
	if (value.startsWith("+") || value.startsWith("-")) {
		value = value.substring(1);
	}
	return value;
}

export function removeZeroInvalidInWholeNumberPart(value) {
	value = value.toString().replace(/^0+(?!$)/g, '');
	// bắt đầu bằng list số 0, bị regex hết thì phải return [0.]....
	if (value.startsWith(".")) {
		value = `0${value}`;
	}
	return value;
}

export function removeZeroInvalidInDecimalPart(value) {
	if (value.includes(".")) {
		let index = 0
		let numCharNotRemove = value.length;
		for (index = value.length - 1; index > value.indexOf('.') - 1; index--) {
			if (value.charAt(index) === '0') {
				numCharNotRemove = index;
			} else {
				break;
			}
		}
		// nếu sau dấu [.] toàn là số 0 thì chuyển thành số nguyên
		if (value.indexOf('.') === numCharNotRemove - 1) {
			numCharNotRemove = numCharNotRemove - 1;
		}
		value = value.substring(0, numCharNotRemove);
	}
	return value;
}

export function removeInvalidCharactersNumber(value) {
	if (isStringEmpty (value)) {
		return value;
	}
	let newValue = (value || "");
	// không phải là số thì return
	if (!isNumeric(newValue)) {
		return newValue;
	}
	newValue = getStringAbsoluteValue(newValue);
	// loại bỏ những số 0 ở đầu
	newValue = removeZeroInvalidInWholeNumberPart(newValue);
	// bỏ số 0 không có nghĩa ở cuối, sau dấu [.]
	newValue = removeZeroInvalidInDecimalPart(newValue);
	// nếu là số âm thì update lại value
	if (value.startsWith("-")) {
		return `-${newValue}`;
	}
	return newValue;
}

export function validDataNumber(value, maxLengthString) {
	let dataResult = {
		valid: true,
		value: ''
	}
	if (isStringEmpty(value)) {
		dataResult.valid = true;
		dataResult.value = value;
		return dataResult;
	}
	value = (value || "").trim();
	// bỏ những số 0 không có nghĩa ở đầu/cuối
	value = removeInvalidCharactersNumber(value);

	let regexNum ="";	
	let zeroNegativeNumReg = "";
	if (!maxLengthString.includes(".")) {
		regexNum = RegExp(`^[+-]?\\d{1,${parseInt(maxLengthString)}}$`);
		zeroNegativeNumReg = RegExp(`^[+-][0]{1,${parseInt(maxLengthString)}}$`);
	} else {
		let maxLengthdecimalPart = maxLengthString.split(".").length > 1 ? parseInt(maxLengthString.split(".")[1]) : 1;
		regexNum = RegExp(`^[+-]?\\d{1,${parseInt(maxLengthString.split(".")[0])}}(\\.\\d{1,${maxLengthdecimalPart}})?$`);
		zeroNegativeNumReg = RegExp(`^[+-][0]{1,${parseInt(maxLengthString.split(".")[0])}}(\\.[0]{1,${maxLengthdecimalPart}})?$`);
	}
	// [+-]0{n} ~ toàn số 0 => thì chuyển thành 0
	if (zeroNegativeNumReg.test(value)) {
		value = '0';
	}
	if (!regexNum.test(value)) {
		value = null;
		dataResult.valid = false;
	}
	dataResult.value = value;
	return dataResult;
}

export function encryptItemDataHidden(data) {
    if (typeof data === "undefined" || data.toString().trim()===''){
        return data;
    }
    let encryptedData = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(CONSTANTS.SECRET_KEY_ENCODE_DATA_HIDDEN), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encryptedData.toString();
}

export function decryptItemDataHidden(dataEncrypt) {
    if (typeof dataEncrypt === "undefined" || dataEncrypt.toString().trim()===''){
        return dataEncrypt;
    }
    let decryptedData = CryptoJS.AES.decrypt(dataEncrypt, CryptoJS.enc.Utf8.parse(CONSTANTS.SECRET_KEY_ENCODE_DATA_HIDDEN), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    let decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
    return decryptedText;
}

export const urlActionMap = {
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/site/[1-9][0-9]*/subject-no/[^/]+/visit-matrix" : "FORM_VISIT_MATRIX_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/site/[1-9][0-9]*/subject-no/[^/]+/matrix/" : "CRF_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/site/[1-9][0-9]*/subject-create": "SUBJECT_REGISTER",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/site/[1-9][0-9]*/site-register": "SITE_CREATE",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/site/[1-9][0-9]*/site-edit": "SITE_EDIT",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/site": "SITE_LIST_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/user-history-report": "USR_HIS_RPT_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/user/add" : "USER_REGISTER",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/user/edit" : "USER_EDIT",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/user": "USER_LIST_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/subject-pdf": "SUBJECT_PDF_HIS_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/subject": "SUBJECT_LIST_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/query": "QUERY_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/exam-info": "STUDY_INFO_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/status-visit": "STATUS_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/status-subject": "STATUS_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/status-site": "STATUS_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/status": "STATUS_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/page-status": "STATUS_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/block-status": "STATUS_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/question-status": "STATUS_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/line-status": "STATUS_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/download-dataset": "DATA_SET_HISTORY_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/eCrf": "ECRF_DOWNLOAD",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/dblock": "DB_LOCK_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/delivery" : "DELIVERY_DRUG_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/blind-list": "BLIND_LIST_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/list-upload/arm": "LIST_UPLOAD_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/econsent-history": "ECONSENT_HISTORY_VIEW",
	"/study/[1-9][0-9]*/role/[1-9][0-9]*/ecs-history": "ECS_HISTORY_VIEW",
}
