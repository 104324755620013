module.exports = ({
    "PLACEHOLDER" : {
        "CDMS101P001" : "メールアドレスを入力してください",
        "CDMS101P002" : "秘密の答えを入力してください",
        "CDMS103P001" : "現在のメールアドレスを入力してください",
        "CDMS103P002" : "ユーザー名称を入力してください",
        "CDMS103P003" : "新しいパスワードを入力してください",
        "CDMS103P004" : "新しいパスワードの確認を入力してください",
    },
    "MSG" : {
        "CDMS101MSG001" : "メールアドレスを入力してください。",
        "CDMS101MSG002" : "入力されたメールアドレスは登録されていません。",
        "CDMS101MSG003" : "CDMS101MSG003",
        "CDMS101MSG004" : "初回に設定した秘密の質問と異なります。",
        "CDMS101MSG005" : "秘密の質問に対する回答を入力してください。",
        "CDMS101MSG006" : "秘密の質問に対する回答が異なります。",
        "CDMS103MSG001" : "CDMS103MSG001",
        "CDMS103MSG002" : "CDMS103MSG002",
        "CDMS103MSG003" : "CDMS103MSG003",
        "CDMS103MSG004" : "CDMS103MSG004",
        "CDMS103MSG005" : "以前に登録されたパスワードと同様のパスワードが設定されています。",
        "CDMS103MSG006" : "CDMS103MSG006",
        "CDMS103MSG007" : "CDMS103MSG007",
        "CDMS103MSG008" : "CDMS103MSG008",
        "CDMS103MSG009" : "CDMS103MSG009",
        "CDMS104MSG001" : "CDMS104MSG001",
    },

    "SM130.TITLE":"パスワード設定",
    "SM130.PASSWORD.LABEL":"新しいパスワード",
    "SM130.PASSWORD.PLACEHOLDER":"新しいパスワードを入力してください",
    "SM130.CONFIRMPASSWORD.LABEL":"新しいパスワードの確認",
    "SM130.QUESTION.LABEL":"秘密の質問",
    "SM130.ANSWEAR.LABEL":"回答",
    "SM130.ANSWEAR.PLACEHOLDER":"回答を入力してください",
    "SM130.BUTTON.TO.LOGIN":"ログインに戻る",
    "SM130.BUTTON.SUBMIT":"保存",
    "SM130.MESSAGE.SUCCESS":"パスワード設定が完了しました。",
    "SM120.BUTTON.SUBMIT":"ID検索",
    "SM120.MESSAGE.SUCCESS":"ユーザIDをメールに送信しました。",
    "SM120MSG001":"入力されたメールアドレスは登録されていません。",
    "SM120MSG005":"メールアドレスを入力してください。",
    "SM120MSG004":"秘密の質問に対する回答が異なります。",
    "SM120.LABEL.EMAIL":"メールアドレス",
    "SM120.LABEL.PLACEHOLDER":"メールアドレスを入力してください",
    "SM120.TITLE":"ユーザIDを忘れた場合",
    "SM110.TITLE":"パスワードを忘れた場合",
    "SM110.BUTTON.SUBMIT":"パスワード初期化",
    "SM110.SUCCESS.TITLE":"パスワードを忘れた場合",
    "SM110.SUCCESS.CONTENT":"新しいパスワードをメールで送信しました。",
    "PWD_CONFIRM_BLANK":"新しいパスワードの確認を入力してください。",
    "NEW_PWD_BLANK":"新しいパスワードを入力してください。",


    "CDMS104MSG001" : "CDMS104MSG001",
    "CDMS101MSG004" : "CDMS101MSG004",
    "CDMS103MSG005" : "CDMS103MSG005",
    "CDMS101MSG005" : "CDMS101MSG005",
    "CDMS103MSG006" : "CDMS103MSG006",
    "CDMS103MSG007" : "CDMS103MSG007",
    "CDMS103MSG008" : "CDMS103MSG008",
    "CDMS101MSG006" : "CDMS101MSG006",

    "CDMS101P002" : "CDMS101P002",
    "CDMS103P003" : "CDMS103P003",
    "CDMS103P004" : "CDMS103P004"
});