module.exports = ({
    "CDMS900MSG001": "Are you sure you want to delete the selected sites?",
    "CDMS900MSG002": "Are you sure you want to restore the selected sites?",
    "CDMS900MSG003": "Sites cannot be deleted because of containing registered subjects.",
    "CDMS900MSG004": "Sites cannot be deleted because of delivering IP No.",

    "CDMS900H001": "Site ID",
    "CDMS900H002": "Site Name",
    "CDMS900H003": "Country",
    "CDMS900H004": "Contract Number",
    "CDMS900H005": "Contract Date",
    "CDMS900H006": "Audit Trail",
    "CDMS900H007": "Address",
    "CDMS900H008": "Timezone",
    "CDMS900H009": "Version",
    "CDMS900H010": "Effective Date",
    "CDMS900H011": "Department",
    "CDMS900H012": "Updated User",
    "CDMS900H013": "Last Updated Time",
    "CDMS900H014": "eConsent",

    "CDMS900TT001": "Site Management",
    "CDMS900TT002": "Audit Trail - {siteCode}",

});