import request from '../APIUtils.js';
import { ACCESS_TOKEN } from 'constants/index';
import {convertToLowerCase} from "../Helpers";

export function login(loginRequest) {
    loginRequest.username = convertToLowerCase(loginRequest.username)
    return request({
        url: "/api/auth/login",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

/**
 *
 * @param reAuthRequest => {userLoginId : "", password : ""}
 * @return {*}
 */
export function reAuthenticate(reAuthRequest) {
    reAuthRequest.userLoginId = convertToLowerCase(reAuthRequest.userLoginId)
    return request({
        url: "/api/auth/re-auth",
        method: 'POST',
        body: JSON.stringify(reAuthRequest)
    });
}

export function getCurrentUser() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: "/api/auth/me",
        method: 'GET'
    });
}

export function changePwd(userObj) {
    return request({
        url: "/api/auth/change-pwd",
        method: 'POST',
        body: JSON.stringify(userObj),
        dataType: "json",
    });
}

export function updateLoginId(data) {
    return request({
        url: "/api/auth/update-loginid",
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

export function updateSecretQuestion(data) {
    return request({
        url: "/api/auth/update-secret-question",
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

export function changePassword(data) {
    return request({
        url: "/api/auth/update-password",
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

export function resetPassword(data) {
    return request({
        url: "/api/auth/reset-pwd",
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

export function updatePassword(admin) {
    return request({
        url: "/api/auth/update-admin",
        method: 'POST',
        body: JSON.stringify(admin),
        dataType: "json",
    });
}

export function forgetLoginId(data) {
    return request({
        url: "/api/auth/forget-loginid",
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

export function forgetPassword(data) {
    return request({
        url: "/api/auth/forget-password",
        method: 'POST',
        body: JSON.stringify(data),
        dataType: "json",
    });
}

/**
 * @author vungpv@ominext.com
 * @description Get Info User Basic By Email
 * @param String email
 * @return userObject || false
 */
export function getUserBasicByEmail(params) {
    return request({
        url: "/api/study/" + params.studyId + "/user/basic",
        method: 'POST',
        body: JSON.stringify(params),
        dataType: "json",
    });
}