module.exports = ({
    "CDMS800TT001": "User History Report",
    "CDMS800H001": "組織情報",
    "CDMS800H002": "国名",
    "CDMS800H003": "組織名称",
    "CDMS800H004": "ユーザー情報",
    "CDMS800H005": "ユーザーID",
    "CDMS800H006": "ユーザー名称",
    "CDMS800H007": "試験情報",
    "CDMS800H008": "ロール",
    "CDMS800H009": "施設コード",
    "CDMS800H010": "施設名",
    "CDMS800H011": "日付",
    "CDMS800H012": "ステータス",
    "CDMS800OPT001": "Active",
    "CDMS800OPT002": "Inactive",
});