module.exports = ({
    "CDMS803TT001": "Subject PDF",
    "CDMS803TT002": "Subject PDF_History",
    "CDMS803H001": "Site ",
    "CDMS803H002": "Subject ID",
    "CDMS803H003": "Visit",
    "CDMS803H004": "Page",
    "CDMS803H005": "Number of Sites",
    "CDMS803H006": "Number of Subjects",
    "CDMS803H007": "User ID",
    "CDMS803H008": "User Name",
    "CDMS803H009": "Start Date",
    "CDMS803H010": "End Date",
    "CDMS803H011": "File",
    "CDMS803H012": "Status",
    "CDMS803BTN001": "Download History",
    "CDMS803BTN002": "Download",
    "CDMS803BTN003": "Subject PDF",
    "CDMS803OPT001": "Display Deleted Subjects",
    "CDMS803OPT002": "Success",
    "CDMS803OPT003": "Fail",
    "CDMS803OPT004": "In Progress",
    "CDMS803OPT005": "N/A"
});