module.exports = ({

    "CDMS300TT001": "e-Training",

    "CDMS300BTN001": "Download Certificate",

    "CDMS300H001": "Document Name",
    "CDMS300H002": "Completion Status",
    "CDMS300H003": "Completion Date",

    "CDMS300OPT001": "Completed",
    "CDMS300OPT002": "Incompleted",
    "CDMS300PL001": "Document",
    "CDMS300PL002": "Page",

    // system e-training
    "TAB-SYSTEM-ETRAINING": "System e-Training",
    "TABLE-CM-NO": "No.",
    "TABLE-CM-USER-ID": "User ID",
    "TABLE-COLUMN-SITE": "Site",
    "TABLE-COLUMN-ACTIVE-STATUS": "Active",
    "TABLE-COLUMN-ETRAINING-FILE": "e-Training Content",
    "TABLE-COLUMN-COMPLETION-STATUS": "Completion",
    "TABLE-COLUMN-COMPLETION-DATE": "End date",
    "TABLE-COLUMN-DOWNLOAD-CERTIFICATE": "Certificate",
    "BUTTON-CM-DOWNLOAD-ALL": "Download All",
    "LABEL-ACTIVE": "Active",
    "LABEL-INACTIVE": "Inactive",
    "LABEL-COMPLETED": "Completed",
    "LABEL-INCOMPLETED": "Incompleted",
    "BUTTON-CM-DOWNLOAD": "Download",
});
