module.exports = ({
    "VISIT.STATUS.TITLE": "ステータス（Visit)",
    "SUBJECT.STATUS.TITLE": "ステータス（被験者）",
    "SITE.STATUS.TITLE": "ステータス（施設）",
    
    //column name
    "COLUMN.SITE.NAME" : "施設",
    "COLUMN.SUBJECT.NO" : "被験者番号",
    "COLUMN.VISIT" : "Visit",
    "COLUMN.QUESTION.STATUS" : "入力状況",
    "COLUMN.QUESTION.OPEN" : "オープンクエリー",
    "COLUMN.QUESTION.ANSWERED" : "クエリー回答",
    "COLUMN.FREEZE.FLAG" : "Freeze",
    "COLUMN.LOCK.FLAG" : "Lock",
    "COLUMN.SDV.FLAG" : "SDV",
    "COLUMN.REVIEW.FLAG" : "Review",
    "COLUMN.ESIGN.FLAG" : "電子署名",
    //button
    "BUTTON.VERIFY": "保存",
    "BUTTON.DOWNLOAD.EXCEL": "ダウンロード",
    // placeholder
    "CDMS700P001": "ユーザーIDを入力してください",
    "CDMS700P002": "パスワードを入力してください",
    //message
    "CDMS700MSG001": "データがありません",
    "CDMS700MSG002": "ユーザーIDを入力してください。",
    "CDMS700MSG003": "パスワードを入力してください。",
    "CDMS700MSG004": "ユーザIDまたはパスワードが異なります。",
    "COMMSG001": "ステータスが保存されました。",
    "CDMS700MSG006" : "一部のデータが変更されたため、更新できません。\nページをリフレッシュしてください。",
    "CDMS700MSG007" : "{level}の中にLockされた対象がありましたため、その対象に関係ないものだけでステータスが設定されました。",
});