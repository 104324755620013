module.exports = ({
   "CDMS802.STUDY_NAME": "試験名",
   "CDMS802.VERSION": "バージョン",
   "CDMS802.LANGUAGE": "言語",
   "CDMS802.ANNOTATED": "注釈付き",
   "CDMS802.BLANK": "Blank CRF",
   "CDMS802.UNIQUE_FORM": "ユニークフォーム",
   "CDMS802.DOWNLOAD": "ダウンロード",

   "CDMS802TT001": "eCRF",
   "CDMS802TT002": "eCRF_History",

   "CDMS802L001": "試験名",
   "CDMS802L002": "バージョン",
   "CDMS802L003": "ユニークフォーム",
   "CDMS802L004": "言語",
   "CDMS802OPT001": "日本語",
   "CDMS802OPT002": "English ",
   "CDMS802OPT003": "注釈付き",
   "CDMS802OPT004": "Blank CRF",

   "CDMS802BTN001": "ダウンロード 履歴",
   "CDMS802BTN002": "eCRF画面へ",

    "CDMS802H001": "試験名",
    "CDMS802H002": "バージョン",
    "CDMS802H003": "ユニークフォーム",
    "CDMS802H004": "言語",
    "CDMS802H005": "種類",
    "CDMS802H006": "開始日",
    "CDMS802H007": "終了日",
    "CDMS802H008": "ファイル",
    "CDMS802H009": "状態",
});