module.exports = ({
    "CDMS801TT001": "Dataset",
    "CDMS801TT002": "Dataset_History",
    "CDMS801LB001": "Export Rule",
    "CDMS801LB002": "Status",
    "CDMS801H001": "Site",
    "CDMS801H002": "Subject ID",
    "CDMS801H003": "Visit",
    "CDMS801H004": "Source Selection",
    "CDMS801H005": "Domain Selection",
    "CDMS801H006": "User ID",
    "CDMS801H007": "User Name",
    "CDMS801H008": "Start Date",
    "CDMS801H009": "End Date",
    "CDMS801H010": "File",
    "CDMS801H011": "Status",
    "CDMS801BTN001": "Excel Download",
    "CDMS801BTN002": "CSV Download",
    "CDMS801BTN003": "Access Download",
    "CDMS801BTN004": "SAS Download",
    "CDMS801BTN005": "Download History",
    "CDMS801BTN006": "Dataset",
    "CDMS801OPT001": "Code",
    "CDMS801OPT002": "Label",
    "CDMS801OPT003": "Code & Label",
    "CDMS801OPT004": "SDV",
    "CDMS801OPT005": "Review",
    "CDMS801OPT006": "Freeze",
    "CDMS801OPT007": "Lock",
    "CDMS801OPT008": "eSign",
    "CDMS801OPT009": "Data",
    "CDMS801OPT010": "System Information",
    "CDMS801OPT011": "Audit Trail",
});