module.exports = ({
    "CDMS901P001": "施設コードを入力してください",
    "CDMS901P002": "施設名を入力してください",
    "CDMS901P003": "住所を入力してください",
    "CDMS901P004": "契約例数を入力してください",
    "CDMS901P005": "診療科を入力してください",

    "CDMS901MSG001": "施設コードを入力してください。",
    "CDMS901MSG002": "施設名を入力してください。",
    "CDMS901MSG003": "適用日を最初の被験者の登録日以前設定してくさい。",
    "CDMS901MSG004": "適用日を入力してください。",
    "CDMS901MSG005": "入力された施設コードがすでに使用されています。",

    "CDMS901LB001": "施設コード",
    "CDMS901LB002": "施設名",
    "CDMS901LB003": "住所",
    "CDMS901LB004": "国名",
    "CDMS901LB005": "標準時間帯",
    "CDMS901LB006": "バージョン",
    "CDMS901LB007": "適用日",
    "CDMS901LB008": "契約例数",
    "CDMS901LB009": "契約日",
    "CDMS901LB010": "診療科",
    "CDMS901LB011": "施設",
    "CDMS901LB012": "システム連携",

    "CDMS901TT001": "施設登録",
    "CDMS902TT001": "施設更新",
    
    "CDMS901OPT001": "eConsent",

});