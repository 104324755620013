import _ from 'lodash'

export function getRoles(studyId, roleId) {
	const actionKey = "actions_" + studyId + "_" + roleId
	localStorage.getItem(actionKey)
	const listActions = JSON.parse(localStorage.getItem(actionKey))
	return listActions
}

export function hasRole(roleList, roleName) {
	return _.includes(roleList, roleName)
}

export function hasAnyRole(roleList, roleToCheckList) {
	return roleToCheckList.some(r => roleList.indexOf(r) >= 0);
}