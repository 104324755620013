module.exports = ({
    "CDMS400.CONFIRM.TITLE": "確認",
    "CDMS400.LISTBOX.TITLE": "施設選択",
    "CDMS400.TITLE": "症例一覧",
    "CDMS400MSG002": "被験者を有効化します。\nよろしいですか。",
    "CDMS400MSG003": "被験者を無効化します。\nよろしいですか。",
    "CDMS400MSG004": "登録ページが存在しません。",
    "CDMS400MSG005": "複数の登録ページが設定されています。",
    "CDMS400MSG006": "登録ページにScreening numberが設定されていません。",
    "CDMS400MSG007": "登録ページのScreening numberのレイアウトが適切に設定されていません。",
    "CDMS400MSG008": "登録ページのEnrollment numberのレイアウトが適切に設定されていません。",
    "CDMS400MSG009": "登録ページにEnrollment numberとScreening numberが同じ項目に設定されています。",
    "CDMS400MSG010": "施設のバージョンが正しく設定されないため、症例を登録できません。",
    "CDMS400.SITENAME": "施設名",
    "CDMS400.STATUS": "ステータス",
    "CDMS400.STATUS.SAVED": "保存済",
    "CDMS400.REGISTER.DATE": "登録日",
    "CDMS400.SUBJECTNO": "被験者番号",
    "CDMS400.SITECODE": "施設コード",
    "CDMS400.OPENQUERY": "オープンクエリー数",
    
    "CDMS400.BTN.DOWNLOAD": "ダウンロード",
    "CDMS400.BTN.ENDABLE": "無効化",
    "CDMS400.BTN.DISABLE": "再有効化",
    "CDMS400.BTN.ADD.SUBJECT": "被験者追加",
});