module.exports = ({
    "PLACEHOLDER": {
        "CDMS101P001" : "メールアドレスを入力してください",
        "CDMS103P002" : "ユーザー名称を入力してください",
        "CDMS103P001" : "現在のメールアドレスを入力してください",
        "CDMS101P002" : "秘密の答えを入力してください",
        "CDMS103P003" : "新しいパスワードを入力してください",
        "CDMS103P004" : "新しいパスワードの確認を入力してください"
    },
    "CDMS103.BUTTON.BACK" : "前の画面に戻る",
    "CDMS103.BUTTON.SUBMIT" : "保存",

    // Message
    "CDMS101MSG001" : "メールアドレスを入力してください。",
    "CDMS101MSG002" : "入力されたメールアドレスは登録されていません。",
    "CDMS101MSG003" : "CDMS101MSG003",
    "CDMS101MSG004" : "初回に設定した秘密の質問と異なります。",
    "CDMS101MSG005" : "秘密の質問に対する回答を入力してください。",
    "CDMS101MSG006" : "初回に設定した秘密の質問と異なります。",
    "CDMS103MSG005" : "以前に登録されたパスワードと同様のパスワードが設定されています。",
});