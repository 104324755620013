module.exports = ({
    "CDMS101P001": "メールアドレスを入力してください",
    "CDMS101P002": "秘密の答えを入力してください",
    "CDMS101MSG001":"メールアドレスを入力してください。",
    "CDMS101MSG002":"入力されたメールアドレスは登録されていません。",
    "CDMS101MSG003":"秘密の質問に対する回答を入力してください。",
    "CDMS101MSG004":"初回に設定した秘密の質問と異なります。",
    "CDMS101MSG005":"パスワードを初期化しました。<br>新しいパスワードをメールに送信しました。",

    "CDMS101BTN001": "初期化",
    "CDMS101LB001": "メールアドレス",
    "CDMS101LB002": "秘密の質問と答えを選択してください",
    "CDMS101LB003": "秘密の質問",
    "CDMS101LB004": "秘密の答え",
    "CDMS101TT001": "パスワード忘れ",

    "CDMS102TT001": "ユーザーID忘れ",
    "CDMS102TT002": "ユーザーIDを忘れた場合",
    "CDMS102MSG001":"ユーザーIDをメールに送信しました。",

    "CDMS102BTN001": "送信",
    "CDMS102BTN002": "ログインに戻る",
    "COMBTN025": "ログインに戻る",
});