export const CONSTANTS = {
    // id of system user 
    DB_SYSTEM_USER_ID : 0,
    //item layout
    DB_ITEM_LAYOUT_HORIZONTAL_CHECKBOX: 1,
    DB_ITEM_LAYOUT_DATE_YYYY_MM_DD: 2,
    DB_ITEM_LAYOUT_DATE_YYYY_MM_UN: 3,
    DB_ITEM_LAYOUT_DATE_YYYY_UN_UN: 4,
    DB_ITEM_LAYOUT_DATE_UNUN_UN_UN: 5,
    DB_ITEM_LAYOUT_DROPDOWN_LIST: 6,
    DB_ITEM_LAYOUT_LABEL: 7,
    DB_ITEM_LAYOUT_RADIO_VERTICAL: 8,
    DB_ITEM_LAYOUT_RADIO_HORIZONTAL: 9,
    DB_ITEM_LAYOUT_TEXT: 10,
    DB_ITEM_LAYOUT_TEXT_AREA: 11,
    DB_ITEM_LAYOUT_TIME_HH_MM: 12,
    DB_ITEM_LAYOUT_TIME_HH_UN: 13,
    DB_ITEM_LAYOUT_TIME_UN_UN: 14,
    DB_ITEM_LAYOUT_ROW_NO: 15,

    //query type in db
    DB_QUERY_TYPE_SYSYEM: 1,
    DB_QUERY_TYPE_MANUAL: 2,

    //query status in db
    DB_QUERY_STATUS_OPEN: 1,
    DB_QUERY_STATUS_CANCELED: 2,
    DB_QUERY_STATUS_ANSWERED: 3,
    DB_QUERY_STATUS_CLOSED: 4,
    DB_QUERY_STATUS_REOPENED: 5,

    DB_QUERY_CLOSED_MANUAL: 1,

    DB_QUERY_REOPEN_FLAG_ENABLE: 1,
    DB_QUERY_REOPEN_FLAG_NONE: 0,

    //query mass update type
    MASS_UPDATE_QUERY_TYPE_CLOSE : 1,
    MASS_UPDATE_QUERY_TYPE_REOPEN : 2,
    MASS_UPDATE_QUERY_TYPE_EDIT : 3,

    //item property
    DB_ITEM_PROPERTY_SCREEN_TYPE: 8,
    DB_ITEM_PROPERTY_ENROLL_TYPE: 9,
    DB_ITEM_PROPERTY_AUTOFILL_TYPE: 1,
    DB_ITEM_PROPERTY_DEFAULT_TYPE: 2,
    DB_ITEM_PROPERTY_LABEL_TOP_TYPE: 3,
    DB_ITEM_PROPERTY_LABEL_BOT_TYPE: 4,
    DB_ITEM_PROPERTY_LABEL_LEFT_TYPE: 5,
    DB_ITEM_PROPERTY_LABEL_RIGHT_TYPE: 6,
    DB_ITEM_PROPERTY_EXCLUDE: 7,
    DB_ITEM_PROPERTY_HIDDEN: 14,
    DB_ITEM_PROPERTY_RANDOM_KEY_TYPE: 10,
    DB_ITEM_PROPERTY_ARM_GENERATE_TYPE: 11,
    DB_ITEM_PROPERTY_IP_GENERATE_TYPE: 12,
    DB_ITEM_PROPERTY_STRATIFICATION_TYPE: 13,
    DB_ITEM_PROPERTY_ECONSENT_DATE_TYPE: 15,

    DB_DELETE_FLAG : 1,
    DB_UNDELETE_FLAG : 0,
    
    DB_LOCK_DISABLE: 0,
    DB_LOCK_ENABLE: 1,

    FIRST_TIME_DISPLAY_FLAG : 1,
    FIRST_TIME_UNDISPLAY_FLAG : 0,
    
    //Date constants
    DATE_RESTRICT: /^[0-9UNun年月日-]+$/,

    //for all sdv, freeze, review, lock, esigned flag, na flag in db
    DB_STATUS_UNCHECKED_FLAG : 0,
    DB_STATUS_CHECKED_FLAG : 1,
    DB_STATUS_INPUT_FLAG_UN_INPUT : 0,
    DB_STATUS_INPUT_FLAG_PARTITIAL_INPUT : 1,
    DB_STATUS_INPUT_FLAG_ALL_INPUT : 2,

    DB_PAGE_TYPE_REGISTERATION : 1,
    DB_PAGE_TYPE_NORMAL : 2,

    DB_BLOCK_TYPE_NORMAL : 1,
    DB_BLOCK_TYPE_FIX_LINE : 2,
    DB_BLOCK_TYPE_LOG_LINE : 3,

    AUDIT_REVISION_TYPE_INITIAL : 0,
    AUDIT_REVISION_TYPE_MODIFY : 1,
    AUDIT_REVISION_TYPE_DELETE : 2,

    DB_ENABLE_FLAG: 1,
    DB_DISABLE_FLAG: 0,
    //
    DB_STATUS_NA_FLAG : 1,
    DB_STATUS_NOT_NA_FLAG : 0,
    DB_STATUS_SDV_EPRO_NA : 2,
    //
    SECRET_KEY_ENCODE_DATA_HIDDEN : 'omihiddenencode1',
    //
    DB_ITEM_DATA_EDITED_REASON_TYPE_ITEM_HIDDEN : 20,

    // edit reason type
    ITEM_DATA_EDIT_REASON_TYPE_ECONSENT : "ECONSENT",

    DB_ECS_SERVICE_STATUS : {
        NEW: 1,
        PROCESSING: 2,
        FINISH:3,
        ERROR: 99,
        BLANK:0
    },

    // epro
    DB_CRF_GROUP_TYPE_EDC : 1,
    DB_CRF_GROUP_TYPE_EPRO : 2,
    DB_CRF_GROUP_TYPE_EDC_AND_EPRO : 3

}