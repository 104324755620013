module.exports = ({

    "CDMS402MSG011": "保存したページは存在しません。",

    "CDMS402.MODAL.EDIT" : "修正理由",
    "CDMS402.BUTTON.VERIFI_HANDLE_STAY" : "保存後、新規症例追加",
    "CDMS402.BUTTON.VISIT_FORM_MATRIX" : "Visit&Form Matrix",
    "CDMS402.BUTTON.NEXT_PAGE" : "次のページ",
    "CDMS402.BUTTON.LIST_TO_SUBJECT" : "被験者一覧へ",
    "CDMS402.BOX.CREATE_QUERY": "クエリー作成：",
    "CDMS402.BOX.SELECT_CREATE_QUERY": "クエリーを作成した項目を選択して下さい。",
    "CDMS402.BOX.QUERY_CONTENT": "クエリーの内容を入力して下さい。",
    "CDMS402.BOX.REQUIRED_CONTENT": "クエリーの内容を入力してください。",
    "CDMS402.COMMENT.PLACEHOLDER": "コメント...",
    "CDMS402.COMMENT.BUTTON.DELETE": "削除",
    "CDMS402.COMMENT.BUTTON.EDIT": "修正",
    "CDMS402.COMMENT.LABEL": "【コメント】",

    "CDMS402.AUDITTRAIL.TABLE.DATE": "日付",
    "CDMS402.AUDITTRAIL.TABLE.USER": "ユーザー（使用者）",
    "CDMS402.AUDITTRAIL.TABLE.LABEL": "ラベル",
    "CDMS402.AUDITTRAIL.TABLE.BEFORE": "以前",
    "CDMS402.AUDITTRAIL.TABLE.NEXT": "次",
    "CDMS402.AUDITTRAIL.TABLE.REASON": "理由",
    "CDMS402.AUDITTRAIL.TABLE.REASON.QUERY": "回答",

    "CDMS402.QUERY.TABLE.ITEM": "項目",
    "CDMS402.QUERY.TABLE.GROUP_NUMBER": "グループ番号",
    "CDMS402.QUERY.TABLE.DATE": "日付",
    "CDMS402.QUERY.TABLE.TYPE": "タイプ",
    "CDMS402.QUERY.TABLE.QUERY": "クエリー",
    "CDMS402.QUERY.TABLE.STATUS": "状態",
    "CDMS402.QUERY.EDIT.DELETE": "削除",
    "CDMS402.QUERY.EDIT.CONTENT": "内容編集",
    "CDMS402.QUERY.EDIT.ERROR": "クエリーの内容を入力してください。",

    "CDMS402.COMMENT.TABLE.CONTENT": "コメント",

    "CDMS402.FREEZE.TABLE.QUESTION": "質問",
    "CDMS402.FREEZE.TABLE.DATE": "日付",
    "CDMS402.FREEZE.TABLE.USER": "ユーザー（使用者）",
    "CDMS402.FREEZE.TABLE.STATUS": "状態",
    "CDMS402.QUERY.REOPEN": "再オープン",

    //401
    "CDMS401P001": "コメント...",
    "CDMS401MSG001": "{label}が未入力です。",
    "CDMS401MSG002": "クエリーが発生したため、保存できません。",
    "CDMS401MSG003": "{type}が自動増やすことができません。",
    "CDMS401MSG004": "症例を追加しますか。",
    "CDMS401MSG005": "症例番号がすでに使用されています。",
    "CDMS400MSG009": "Screening numberは256文字以下を設定してください。",
    "CDMS400MSG010": "Enrollment numberは256文字以下を設定してください。",
    
    "CDMS401BTN001": "修正",
    "CDMS401LB001": "【コメント】",

    //402
    "CDMS402MSG001": "クエリーの内容を入力してください。",
    "CDMS402MSG002": "クエリーの回答を入力してください。",
    "CDMS402MSG003": "クエリーが {count}件発生しました。",
    "CDMS402MSG004": "修正理由を入力してください。",
    "CDMS402MSG005": "入力したデータを保存しますか。",
    "CDMS402BTN001": "被験者一覧へ",
    "CDMS402BTN002": "次のページ",
    "CDMS402BTN003": "編集",
    "CDMS402BTN004": "クエリー回答",
    "CDMS402BTN005": "回答変更",
    "CDMS402BTN006": "クエリー終了/再オープン",
    "CDMS402BTN007": "Visit&Form Matrix",
    "CDMS402LB001": "【クエリー】",
    //"CDMS402LB002":
    "CDMS402LB003": "データ修正を複数実施された場合、同じ修正理由を使用する場合は「はい」を、使用しない場合は「いいえ」を選択してください。",
    "CDMS402LB004": "クエリーを作成した項目を選択して下さい。",
    "CDMS402LB005": "クエリーの内容を入力して下さい。",
    "CDMS402LB006": "クエリー確認の結果を選択して下さい",
    "CDMS402LB007": "{line}行目",
    "CDMS402TT001": "修正理由",
    "CDMS402TT002": "クエリー作成:",
    "CDMS402TT003": "回答",
    "CDMS402TT004": "回答変更",
    "CDMS402OPT001": "入力ミス",
    "CDMS402OPT002": "新情報入手",
    "CDMS402OPT003": "原資料の修正",
    "CDMS402OPT004": "その他",
    "CDMS402OPT005": "削除",
    "CDMS402OPT006": "内容編集",
    "CDMS402OPT007": "クエリー終了",
    "CDMS402OPT008": "オープンクエリー作成（再オープンクエリー）",
    "CDMS402OPT009": "システム",
    "CDMS402OPT010": "マニュアル",
    "CDMS402OPT011": "オープン",
    "CDMS402OPT012": "回答済み ",
    "CDMS402OPT013": "クローズ",
    "CDMS402OPT014": "再オープン",

    "CDMS402HL001": "Data Audit Trail",
    "CDMS402HL002": "Query Audit Trail",
    "CDMS402HL003": "Comment Audit Trail",
    "CDMS402HL004": "Signature Audit Trail",
    "CDMS402HL005": "SDV Audit Trail",
    "CDMS402HL006": "Review Audit Trail",
    "CDMS402HL007": "Lock Audit Trail",
    "CDMS402HL008": "Freeze Audit Trail",

    "CDMS402H001": "日付",
    "CDMS402H002": "ユーザー（使用者）",
    "CDMS402H003": "ラベル",
    "CDMS402H004": "以前",
    "CDMS402H005": "次",
    "CDMS402H006": "理由",
    "CDMS402H007": "項目",
    "CDMS402H008": "グループ番号",
    "CDMS402H009": "タイプ",
    "CDMS402H010": "クエリー",
    "CDMS402H011": "状態",
    "CDMS402H012": "回答",
    "CDMS402H013": "コメント",
    "CDMS402H014": "質問",
    "CDMS402H015": "No.",

    "CDMS402MSG006": "Lockされた対象がありましたため、その対象に関係ないものだけでステータスが設定されました。",
    "CDMS500MSG001": "登録ページがすでに存在したため、このページを入力できません。",

    "CDMS402MSG007": "割付が実施されページが保存されました。",
    "CDMS402MSG008": "この症例の割付因子を判定できない為、割付が実施出来ません。",
    "CDMS402MSG009": "この症例の因子に対するARMを割り当てることができない為、割付を実施出来ません。",
    //#5827
    "CDMS402MSG010": "この症例に割り当てる薬剤が医療機関に割り当てられていないため、割付を実施出来ません。",
    "CDMS402MSG012": "割付が実施されましたがページが保存出来ません。",
    "CDMS402MSG015": "この症例に割付を実施します。\nよろしいでしょうか。",
    "CDMS402MSG016": "閉じていないクエリーがあります。",
});