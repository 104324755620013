module.exports = ({
    "PLACEHOLDER": {
        "CDMS101P001" : "メールアドレスを入力してください",
        "CDMS103P002" : "ユーザー名称を入力してください",
        "CDMS103P001" : "現在のメールアドレスを入力してください",
        "CDMS101P002" : "秘密の答えを入力してください",
        "CDMS103P003" : "新しいパスワードを入力してください",
        "CDMS103P004" : "新しいパスワードの確認を入力してください"
    },
    "CDMS103.BUTTON.BACK" : "前の画面に戻る",
    "CDMS103.BUTTON.SUBMIT" : "保存",
    "CDMS103.TITLE": "基本情報",
    "CDMS103.LOGINID": "ユーザーID",
    "CDMS103.CURRENT_EMAIL": "現在のメールアドレス",
    "CDMS103.FULL_NAME": "ユーザー名称",
    "CDMS103.UPDATE_PASS": "パスワード更新",
    "CDMS103.NEW_PASSWORD": "新しいパスワード",
    "CDMS103.CONFIRM_NEW_PASS": "新しいパスワードの確認",
    "CDMS103.UPDATE_SECRET_QUESTION": "秘密質問更新",
    "CDMS103.SECRET_QUESTION": "秘密の質問",
    "CDMS103.SECRET_ANSWER": "秘密の答え",
    "CDMS101.LABEL.CHOOSE_SECRET_QUESTION" : "秘密の質問と答えを選択してください",

    // Message
    "CDMS104MSG001" : "新しいパスワードを入力してください。", //COMMSG005
    "CDMS101MSG001" : "メールアドレスを入力してください。",
    "CDMS101MSG002" : "入力されたメールアドレスは登録されていません。",
    "CDMS101MSG003" : "ユーザー名称",
    "CDMS103MSG003" : "ユーザー名称",
    "CDMS101MSG004" : "初回に設定した秘密の質問と異なります。",
    "CDMS101MSG005" : "秘密の質問に対する回答を入力してください。",
    "CDMS101MSG006" : "秘密の質問に対する回答が異なります。",
    "CDMS103MSG005" : "以前に登録されたパスワードと同様のパスワードが設定されています。",
    "CDMS103MSG006" : "パスワードは半角で8文字以上（英字と数字と特殊文字の組み合わせ）を入力してください。",
    "CDMS103MSG002" : "現在のメールアドレス",
    "CDMS103MSG001" : "現在のメールアドレス",
    "CDMS103MSG008" : "パスワードが一致しません。",
    "CDMS103MSG009" : "データ",
    "CDMS103MSG010" : "新しいパスワードの確認を入力してください。",

});