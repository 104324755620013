module.exports = ({
    "CDMS707MSG004": "オープンクエリーまたは回答クエリーがあるため、DB Lockできません。",
    "CDMS402BTN001": "レポート出力",
    "CDMS402BTN002": "DB Lock",
    "CDMS402BTN003": "DB Unlock",
    "CDMS707TT002": "DB Lock Status",
    "CDMS707TT003": "DB Lock履歴",
    "CDMS707LB001": "試験ID",
    "CDMS707H001": "試験名",
    "CDMS707H002": "Status",
    "CDMS707H003": "事由",
    "CDMS707LB003": "ステータス",
    "CDMS707LB004": "被験者数",
    "CDMS707LB005": "電子署名した症例数",
    "CDMS707LB006": "CRFページ数",
    "CDMS707LB007": "格納したCRFページ数",
    "CDMS707LB008": "総オープンクエリー",
    "CDMS707LB009": "総回答クエリー",
    "CDMS707H004": "電子署名",
    "CDMS707H005": "電子署名日付",
    "CDMS707MSG001": "事由を入力してください。",
    "CDMS707P001": "事由を入力してください",
    "CDMS707LB010": "事由",
    "CDMS706LB001": "※私はパスワードを入力することにより選択されたフォームの全てのデータが適用される規制に準拠していることを保証いたします。",
    "CDMS700MSG003":"ユーザーIDまたはパスワードが異なります。"
 });