module.exports = ({
    "CDMS707MSG004": "Unable to DB Lock because there is an open or answer query.",
    "CDMS402BTN001": "Export Report",
    "CDMS402BTN002": "DB Lock",
    "CDMS402BTN003": "DB Unlock",
    "CDMS707TT002": "DB Lock Status",
    "CDMS707TT003": "DB Lock History",
    "CDMS707LB001": "Study ID",
    "CDMS707H001": "Study Name",
    "CDMS707H002": "Status",
    "CDMS707H003": "Reason",
    "CDMS707LB003": "Status",
    "CDMS707LB004": "Number of Subjects",
    "CDMS707LB005": "Number of e-Signed Subjects",
    "CDMS707LB006": "Number of CRF Pages",
    "CDMS707LB007": "Number of Saved CRF Pages",
    "CDMS707LB008": "Number of Open Queries",
    "CDMS707LB009": "Number of Answered Queries",
    "CDMS707H004": "e-Signature",
    "CDMS707H005": "e-Signature Date",
    "CDMS707MSG001": "Please enter reason.",
    "CDMS707P001": "Please enter reason",
    "CDMS707LB010": "Reason",
    "CDMS706LB001": "※By entering the password I guarantee that all data in the selected form is complied with applicable regulations.",
    "CDMS700MSG003": "The User ID or password you entered is incorrect. Please try again.",
});