module.exports = ({
    // "CDMS400.CONFIRM.TITLE": "確認",
    // "CDMS400.LISTBOX.TITLE": "施設選択",
    // "CDMS400.TITLE": "症例一覧",
    // "CDMS400MSG002": "被験者を有効化します。\nよろしいですか。",
    // "CDMS400MSG003": "被験者を無効化します。\nよろしいですか。",
    // "CDMS400MSG004": "登録ページが存在しません。",
    // "CDMS400MSG005": "複数の登録ページが設定されています。",
    // "CDMS400MSG006": "登録ページにScreening numberが設定されていません。",
    // "CDMS400MSG007": "登録ページのScreening numberのレイアウトが適切に設定されていません。",
    // "CDMS400MSG008": "登録ページのEnrollment numberのレイアウトが適切に設定されていません。",
    // "CDMS400MSG009": "登録ページにEnrollment numberとScreening numberが同じ項目に設定されています。",
    // "CDMS400MSG010": "施設のバージョンが正しく設定されないため、症例を登録できません。",
    // "CDMS400.SITENAME": "施設名",
    // "CDMS400.STATUS": "ステータス",
    // "CDMS400.STATUS.SAVED": "保存済",
    // "CDMS400.REGISTER.DATE": "登録日",
    // "CDMS400.SUBJECTNO": "被験者番号",
    // "CDMS400.SITECODE": "施設コード",
    // "CDMS400.OPENQUERY": "オープンクエリー数",
    // "CDMS400.BTN.DOWNLOAD": "ダウンロード",
    // "CDMS400.BTN.ENDABLE": "無効化",
    // "CDMS400.BTN.DISABLE": "再有効化",
    // "CDMS400.BTN.ADD.SUBJECT": "被験者追加",

    "CDMS400TT001": "被験者一覧",
    "CDMS400TT002": "施設選択",
    "CDMS400BTN001": "被験者追加",
    "CDMS400BTN002": "表示",
    "CDMS400LB001": "施設コード",
    "CDMS400LB002": "施設名",
    "CDMS400LB003": "被験者番号",
    "CDMS400LB004": "システム登録日",
    "CDMS400LB005": "オープンクエリー数",
    "CDMS400LB006": "ステータス",
    "CDMS400OPT001": "オープンクエリーあり",
    "CDMS400OPT002": "クエリー回答済み",
    "CDMS400OPT003": "ロック済み",
    "CDMS400OPT004": "電子署名済み",
    "CDMS400OPT005": "レビュー済み",
    "CDMS400OPT006": "SDV済み",
    "CDMS400OPT007": "フリーズ済み",
    "CDMS400OPT008": "未入力ページあり",
    "CDMS400OPT009": "保存済",

    "CDMS400MSG001": "被験者を有効化します。\nよろしいですか。",
    "CDMS400MSG002": "被験者を無効化します。\nよろしいですか。",
    "CDMS400MSG003": "登録ページが存在しません。",
    "CDMS400MSG004": "複数の登録ページが設定されています。",
    "CDMS400MSG005": "登録ページにScreening numberが設定されていません。",
    "CDMS400MSG006" : "ECSを実施します。\n よろしいですか。",
    "CDMS400MSG007" : "無効化した症例があるため、ECSが実施できません。",
    "CDMS400MSG008": "施設のバージョンが正しく設定されないため、症例を登録できません。",
    "COMMSG023": "サーバーが過負荷になっています。 少し待ってから、もう一度やり直してください。"
});