module.exports = ({
    "CDMS901P001": "Please enter site id",
    "CDMS901P002": "Please enter site name",
    "CDMS901P003": "Please enter address",
    "CDMS901P004": "Please enter contract number",
    "CDMS901P005": "Please enter department",

    "CDMS901MSG001": "Please enter site id.",
    "CDMS901MSG002": "Please enter site name.",
    "CDMS901MSG003": "Please set the effective date before the registration date of the first subject.",
    "CDMS901MSG004": "Please enter the effective date.",
    "CDMS901MSG005": "This site id has already been in use.",

    "CDMS901LB001": "Site ID",
    "CDMS901LB002": "Site Name",
    "CDMS901LB003": "Address",
    "CDMS901LB004": "Country",
    "CDMS901LB005": "Timezone",
    "CDMS901LB006": "Version",
    "CDMS901LB007": "Effective Date ",
    "CDMS901LB008": "Contract Number",
    "CDMS901LB009": "Contract Date",
    "CDMS901LB010": "Department",
    "CDMS901LB011": "Site",
    "CDMS901LB012": "System",

    "CDMS901TT001": "Site Registration",
    "CDMS902TT001": "Site Editor",

    "CDMS901OPT001": "eConsent",
});