module.exports = ({
    "CDMS911P001": "メールアドレスを入力してください",
    "CDMS911P002": "ユーザー名称を入力してください",
    "CDMS911P003": "電話番号を入力してください",
    "CDMS911P004": "組織を入力してください",

    "CDMS911MSG001": "メールアドレスを入力してください。",
    "CDMS911MSG002": "入力したメールアドレスはすでに登録されています。",
    "CDMS911MSG003": "ユーザーIDが登録されましたが、メールアドレスが異なります。",
    "CDMS911MSG004": "ユーザー名称を入力してください。",
    "CDMS911MSG005": "このメールアドレスのアカウントはすでに登録されていますが、登録されているユーザー名称 [{email}]と異なります。",
    "CDMS911MSG006": "組織を入力してください。",
    "CDMS911MSG007": "ロールを選択してください。",
    "CDMS911MSG008": "施設を選択してください。",
    "CDMS911MSG009": "選択したロールがすでに設定されました。",
    "CDMS912MSG001": "ロールを削除します。\nよろしいですか。",
    "CDMS912MSG002": "開始日を入力してください。",

    "CDMS911LB001": "メールアドレス",
    "CDMS911LB002": "ユーザーID",
    "CDMS911LB003": "ユーザー名称",
    "CDMS911LB004": "電話番号",
    "CDMS911LB005": "組織",
    "CDMS911LB006": "無効化",
    "CDMS911LB007": "ロール",
    "CDMS911LB008": "施設",
    "CDMS911LB009": "削除",
    "CDMS911LB010": "ユーザー",
    "CDMS911LB011": "開始日",
    "CDMS911LB012": "終了日",
    "CDMS911LB013":"言語",

    "CDMS911TT001": "ユーザー登録",
    "CDMS911TT002": "基本情報",
    "CDMS911TT003": "施設",
    "CDMS911TT004": "ユーザー更新",

    "CDMS911OPT001": "All",

});