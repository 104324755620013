/**
 *
 * @param {*} data
 * @param {*} cacheFiledList
 * @param {*} fieldCheckKey
 * @param {*} cacheKeyName
 */
// export function saveTheadDataCache(data, cacheFiledList, fieldCheckKey, cacheKeyName) {
//     let cacheState = { ...data };
//     Object.entries(cacheState).forEach(([key, value]) => {
//         if (!cacheFiledList.includes(key)) {
//             delete cacheState[key];
//             return;
//         }
//         if (cacheState[key][fieldCheckKey] === false) {
//             delete cacheState[key];
//         }
//     });

//     if (Object.keys(cacheState).length === 0) {
//         sessionStorage.removeItem(cacheKeyName);
//     } else {
//         sessionStorage.setItem(cacheKeyName, JSON.stringify(cacheState));
//     }
// }

/**
 *
 * @param {*} stateDefault
 * @param {*} cacheKeyName
 * @returns
 */
// export function getTheadDataCache(stateDefault, cacheKeyName) {
//     const filterRequestCache = sessionStorage.getItem(cacheKeyName)
//         ? JSON.parse(sessionStorage.getItem(cacheKeyName))
//         : null;

//     if (!filterRequestCache) {
//         return stateDefault;
//     }
//     let newState = {};
//     Object.entries(stateDefault).forEach(([key, value]) => {
//         if (!filterRequestCache.hasOwnProperty(key)) {
//             newState[key] = value;
//         } else {
//             newState[key] = JSON.parse(JSON.stringify(filterRequestCache[key]));
//         }
//     });
//     return newState;
// }

/**
 *
 */
export const FILTER_CACHE_KEY = {
    SUBJECT: {
        THEAD: 'subject-thead',
        LIST_SCREEN: 'subject-list',
    },
    EDC_QUESTION_STATUS: {
        THEAD: 'edc-question-status-thead',
        LIST_SCREEN: 'edc-question-status-list',
    },
    EDC_LINE_STATUS: {
        THEAD: 'edc-line-status-thead',
        LIST_SCREEN: 'edc-line-status-list',
    },
    EDC_BLOCK_STATUS: {
        THEAD: 'edc-block-status-thead',
        LIST_SCREEN: 'edc-block-status-list',
    },
    EDC_PAGE_STATUS: {
        THEAD: 'edc-page-status-thead',
        LIST_SCREEN: 'edc-page-status-list',
    },
    EDC_VISIT_STATUS: {
        THEAD: 'edc-visit-status-thead',
        LIST_SCREEN: 'edc-visit-status-list',
    },
    EDC_SUBJECT_STATUS: {
        THEAD: 'edc-subject-status-thead',
        LIST_SCREEN: 'edc-subject-status-list',
    },
    EDC_SITE_STATUS: {
        THEAD: 'edc-site-status-thead',
        LIST_SCREEN: 'edc-site-status-list',
    },
    ETRANING_LIST: {
        THEAD: 'etraining-thead',
        LIST_SCREEN: 'etraining-list'
    },
    SYSTEM_ETRAINING_LIST: {
        THEAD: 'system-etraining-thead',
        LIST_SCREEN: 'system-etraining-list'
    },
    EDC_QUERY_MANAGEMENT: {
        THEAD: 'edc-query-thead',
        LIST_SCREEN: 'edc-query-list'
    },
    USER_HISTORY_REPORT: {
        THEAD: 'user-history-report-thead',
        LIST_SCREEN: 'user-history-report-list'
    },
    ECS_HISTORY: {
        THEAD: 'ecs-history-thead',
        LIST_SCREEN: 'ecs-history-list'
    },
    SITE_LIST: {
        THEAD: 'site-list-thead',
        LIST_SCREEN: 'site-list'
    },
    STUDY_USER_LIST: {
        THEAD: 'study-user-thead',
        LIST_SCREEN: 'study-user-list'
    },
    DELIVERY_STATUS: {
        THEAD: 'delivery-status-thead',
        LIST_SCREEN: 'delivery-status-list'
    },
    DELIVERY_REQUEST: {
        THEAD: 'delivery-request-thead',
        LIST_SCREEN: 'delivery-request-list'
    },
    BLIND_LIST: {
        THEAD: 'blind-list-thead',
        LIST_SCREEN: 'blind-list'
    },
    ECONSENT_HISTORY_LIST: {
        THEAD: 'econsent-history-list-thead',
        LIST_SCREEN: 'econsent-history-list'
    }
};

/**
 *
 */
export class FilterCacheApp {
    /** CACHE DATA*/
    static stateCache = {};

    /**
     *
     * @param {*} prefix
     * @param {*} lastState
     * @param {*} cacheFiledList
     * @param {*} fieldCheckKey
     * @returns
     */
    static updateLastState(prefix, lastState, cacheFiledList, fieldCheckKey) {
        if (!cacheFiledList) {
            this.stateCache = {
                ...this.stateCache,
                [prefix]: lastState,
            };
            console.log('---------------updateLastState------ new data: ', this.stateCache);
            return;
        }
        let cacheState = { ...lastState };
        Object.entries(cacheState).forEach(([key, value]) => {
            if (!cacheFiledList.includes(key)) {
                delete cacheState[key];
                return;
            }
            if (cacheState[key][fieldCheckKey] === false) {
                delete cacheState[key];
            }
        });
        if (Object.keys(cacheState).length === 0) {
            delete this.stateCache[prefix];
        } else {
            this.stateCache[prefix] = cacheState;
        }
        console.log('---------------updateLastState------ new data: ', this.stateCache);
    }

    /**
     *
     * @param {*} prefix
     * @param {*} stateDefault
     * @returns
     */
    static getLastStateByPrefix(prefix, stateDefault) {
        if (!stateDefault) {
            return this.stateCache[prefix];
        }
        const filterRequestCache = this.stateCache[prefix] ? this.stateCache[prefix] : null;

        if (!filterRequestCache) {
            return stateDefault;
        }
        let newState = {};
        Object.entries(stateDefault).forEach(([key, value]) => {
            if (!filterRequestCache.hasOwnProperty(key)) {
                newState[key] = value;
            } else {
                newState[key] = JSON.parse(JSON.stringify(filterRequestCache[key]));
            }
        });
        return newState;
    }

    /**
     *
     * @param {*} prefix
     * @returns
     */
    static existsCacheKey(prefix) {
        if (!prefix) {
            return false;
        }
        return this.stateCache.hasOwnProperty(prefix);
    }

    static deleteByPrefix(prefix) {
        if (this.existsCacheKey(prefix)) {
            delete this.stateCache[prefix];
        }
    }

    static clear() {
        this.stateCache = {};
    }

}

/**
 * get list id checked from list id cache and list id data
 * @param {*} cacheIdList 
 * @param {*} masterDataIdList 
 * @returns 
 */
export const getCheckedIdList = (cacheIdList, masterDataIdList) => {
    return masterDataIdList.filter((id) => cacheIdList.includes(id));
}

/**
 * get list id unchecked from list id cache and list id data
 * @param {*} cacheIdList 
 * @param {*} masterDataIdList 
 * @returns 
 */
export const getHasFilter = (cacheIdList, masterDataIdList) => {
    return masterDataIdList.filter((id) => cacheIdList.includes(id)).length !== masterDataIdList.length;
}

/**
 * get filter condition from cache
 * @param {*} cacheIdList
 * @param {*} masterDataIdList
 * @returns
 */
export const getFilterStatus = (cacheIdList, masterDataIdList) => {
    const checkedIdList = masterDataIdList.filter((id) => cacheIdList.includes(id));
    return {
        checkedList: checkedIdList,
        hasFilter: checkedIdList.length !== masterDataIdList.length,
    };
};
