module.exports = ({

"CDMS910MSG001": "パスワードを初期化します。\nよろしいですか。",
"CDMS910MSG002": "パスワードを初期化しました。\n新しいパスワードをメールに送信しました。",
"CDMS910MSG003": "選択したユーザーを無効化します。\nよろしいですか。",
"CDMS910MSG004": "選択したユーザーを再有効化します。\nよろしいですか。",

"CDMS910H001": "ユーザーID",
"CDMS910H002": "ユーザー名称",
"CDMS910H003": "開始日",
"CDMS910H004": "終了日",
"CDMS910H005": "パスワード最終更新日",
"CDMS910H006": "組織",
"CDMS910H007": "割付ロール",
"CDMS910H008": "使用状況",
"CDMS910H009": "パスワード 初期化",
"CDMS910H010": "Audit",

"CDMS910H011": "No.",
"CDMS910H012": "メールアドレス",
"CDMS910H013": "電話番号",
"CDMS910H014": "修正者",
"CDMS910H015": "修正日付",
"CDMS910H016": "無効化",

"CDMS910H017": "言語",

"CDMS910TT001": "ユーザー管理",
"CDMS910TT002": "{userLoginId}修正履歴",

"CDMS910OPT001": "Active",
"CDMS910OPT002": "Inactive",
"CDMS910OPT003": "Locked",
"CDMS910OPT004": "Not yet",

});