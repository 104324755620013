module.exports = ({
    "CDMS920LB001": "プロトコルNo.",
    "CDMS920LB002": "Alias",
    "CDMS920LB003": "試験名",
    "CDMS920LB004": "施設数",
    "CDMS920LB005": "被験者数",
    "CDMS920LB006": "契約例数のレベル設定",

    "CDMS920TT001": "試験基本情報",

    "CDMS920OPT001": "制限なし",
    "CDMS920OPT002": "試験",
    "CDMS920OPT003": "施設",

});